import { SubscriptionTestComponent } from "./subscription_test_component";

import './table_test_component.css'

interface Props {
    room_id: string;
    subscriptions: any[];
}

export function TableTestComponent(props: Props) {
    return (
        <div>
            <div className="ttc-title">
                {props.room_id}
            </div>
            {
                props.subscriptions.map((subscription, index) => {
                    return (
                        <SubscriptionTestComponent key={index} subscription={subscription} />
                    );
                })
            }
        </div>
    );
}