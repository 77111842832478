import { FFFLoginButton } from "./fff_login_button";
import './fff_landing_page.css'
import { useWalletStore } from "../../../stores/wallet_store";

export function FFFLandingPage() {
    const address = useWalletStore(state => state.address);

    return (
        <div className="fff-lp-root">
            <div className="fff-lp-background">
                <div className="fff-lp-card-0" />
                <div className="fff-lp-card-1" />
                <div className="fff-lp-card-2" />
                <div className="fff-lp-card-3" />
                <div className="fff-lp-card-4" />
            </div>
            <div className="fff-lp-content">
                <div className="fff-lp-header">
                    FAST FOOD FRENS
                </div>
                <div className="fff-game-text">
                    poker
                </div>
                <div className="fff-game-text">
                    tournament
                </div>
                {
                    address !== '' ?
                    <div className="fff-game-text">
                        wallet connected
                    </div>
                    :
                    <div className="fff-lp-login">
                        <FFFLoginButton />
                    </div>
                }
                <div className="fff-lp-footer">
                    to be announced
                </div>
            </div>
        </div>
    );
}