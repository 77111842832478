import { useEffect, useState } from "react";
import { AuthLoginComponent } from "./auth_login_component";
import { AuthSignupComponent } from "./auth_signup_component";
import './auth_page.css'
import { AuthResetPasswordComponent } from "./auth_reset_password_component";
import { useSearchParams } from "react-router-dom";

enum State {
    default = 0,
    login,
    signup,
}

export function AuthPage() {
    const [current_state, set_current_state] = useState(State.default);
    const [recovery_key, set_recovery_key] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.has('rk')) {
            const key = searchParams.get('rk');
            set_recovery_key(key ? key : '');
            searchParams.delete('rk');
            setSearchParams(searchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const on_login = () => {
        set_current_state(State.login);
    }

    const on_signup = () => {
        set_current_state(State.signup);
    }

    const on_recover = () => {
        set_recovery_key('');
    }

    const on_cancel = () => {
        set_current_state(State.default);
    }

    return (
        <div>
            {
                current_state === State.default ?
                    <>
                        {
                            recovery_key === '' ?
                                <>
                                    {/*<div className="ap-error">
                                        ACCOUNT NOT FOUND
                        </div>*/}
                                    <button className='ap-button' onClick={on_login}>
                                        LOGIN
                                    </button>
                                    <div className="ap-text">
                                        OR
                                    </div>
                                    <button className='ap-button' onClick={on_signup}>
                                        SIGNUP
                                    </button>
                                </>
                                :
                                <>
                                    <AuthResetPasswordComponent recovery_key={recovery_key} on_close={on_recover} />
                                    <button className='ap-button' onClick={on_cancel}>
                                        CANCEL
                                    </button>
                                </>
                        }
                    </>
                    :
                    <>
                        {current_state === State.login ?
                            <AuthLoginComponent /> :
                            <AuthSignupComponent />}
                        <button className='ap-button' onClick={on_cancel}>
                            CANCEL
                        </button>
                    </>
            }
        </div>
    );
}