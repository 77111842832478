import './subscription_state_component.css'

interface Props {
    state: string;
}

export function SubscriptionStateComponent(props: Props) {
    const state_class = (state: string) => {
        if (state === 'booked')
            return 'ssc-state-booked';
        if (state === 'won')
            return 'ssc-state-won';
        if (state === 'lost')
            return 'ssc-state-lost';
        if (state === 'left')
            return 'ssc-state-left';
        if (state === 'skipped')
            return 'ssc-state-skipped';
        return 'ssc-state-default';
    }

    const get_state_name = (state: string) => {
        if (state === 'booked')
            return 'in progress';
        return state;
    }

    return (
        <span className={state_class(props.state)}>
            {get_state_name(props.state)}
        </span>
    )
}