import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { post_json } from "../../../../core/fetch";
import settings from "../../../../core/settings";
import storage from "../../../../core/storage";
import { TableTestComponent } from "./table_test_component";

interface Props {
    tournament: any;
}

export function TournamentTestComponent(props: Props) {
    const [deadline, set_deadline] = useState('');
    const [local_datetime, set_local_datetime] = useState('');
    const [connecting, set_connecting] = useState(false);
    const [tables, set_tables] = useState<any[]>([]);
    const [player_count, set_player_count] = useState(0);

    useEffect(() => {
        const date = new Date(props.tournament.datetime);
        set_local_datetime(date.toLocaleString());

        const delta = get_delta();
        console.log(props.tournament.datetime, delta);
        if (delta < 0) {
            return;
        }

        subscribe();

        const connect_timeout_id = setTimeout(on_connect, delta);
        const join_timeout_id = setTimeout(on_join, delta + 5000);
        const tick_interval_id = setInterval(on_tick, 1000);

        return () => {
            clearTimeout(connect_timeout_id);
            clearTimeout(join_timeout_id);
            clearInterval(tick_interval_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const get_delta = () => {
        const now = Date.now();
        const dl = new Date(props.tournament.datetime).getTime();
        return dl - now;
    }

    const subscribe = () => {
        if (props.tournament.parent_id !== '') {
            post_json(settings.tournaments_server_url + '/subscriptions', {
                tournament_id: props.tournament.parent_id
            }, storage.jwt).then(data => {
                console.log(data);
                if (data.status === 200) {
                    if (data.data) {
                        let count = 0;
                        for (const subscription of data.data) {
                            const current_state = subscription.current_state.S;
                            if (current_state === 'won') {
                                post_json(settings.tournaments_server_url + '/create_subscription', {
                                    account_id: subscription.account_id.S,
                                    tournament_id: props.tournament.tournament_id
                                }, storage.jwt).then(data => {
                                    console.log(data);
                                });
                                ++count;
                            }
                        }
                        set_player_count(count);
                    }
                }
            });
        } else {
            const count = props.tournament.max_players;
            set_player_count(count);
            for (let cn = 0; cn < count; ++cn) {
                const account_id = nanoid();
                post_json(settings.tournaments_server_url + '/create_subscription', {
                    account_id,
                    tournament_id: props.tournament.tournament_id
                }, storage.jwt).then(data => {
                    console.log(data);
                });
            }
        }
    }

    const fetch_subscriptions = () => {
        post_json(settings.tournaments_server_url + '/subscriptions', {
            tournament_id: props.tournament.tournament_id
        }, storage.jwt).then(data => {
            console.log(data);
            if (data.status === 200) {
                if (data.data) {
                    let entries: any = {};
                    for (const subscription of data.data) {
                        const room_id = subscription.room_id.S;
                        if (!entries[room_id]) {
                            entries[room_id] = [];
                        }
                        entries[room_id].push(subscription);
                    }
                    let new_tables: any[] = [];
                    for (const room_id in entries) {
                        new_tables.push({
                            room_id,
                            subscriptions: entries[room_id],
                        });
                    }
                    set_tables(new_tables);
                }
            }
        });
    }

    const on_tick = () => {
        const delta = get_delta();
        if (delta >= 0) {
            const delta_in_seconds = Math.floor(delta / 1000);
            const seconds = delta_in_seconds % 60;
            const minutes = Math.floor(delta_in_seconds / 60) % 60;
            const hours = Math.floor(delta_in_seconds / 60 / 60) % 24;
            const days = Math.floor(delta_in_seconds / 60 / 60 / 24);
            set_deadline(days + 'd ' + hours.toString().padStart(2, "0") + 'h ' + minutes.toString().padStart(2, "0") + 'm ' + seconds.toString().padStart(2, "0") + 's');
        } else {
            set_deadline('tournament started');
        }
    }

    const on_connect = () => {
        set_connecting(true);
    }

    const on_join = () => {
        fetch_subscriptions();
    }

    const finish_tournament = () => {
        let fetches_to_wait = player_count;
        const handle_response = (data: any) => {
            console.log(data);
            --fetches_to_wait;
            if (fetches_to_wait === 0) {
                fetch_subscriptions();
            }
        }
        for (let table of tables) {
            const winner_index = Math.floor(Math.random() * table.subscriptions.length);
            for (let cn = 0; cn < table.subscriptions.length; ++cn) {
                const subscription = table.subscriptions[cn];
                const body = {
                    subscription_id: subscription.subscription_id.S,
                    current_state: winner_index === cn ? 'won' : 'lost',
                }
                post_json(settings.tournaments_server_url + '/subscription/current_state', body).then(handle_response);
            }
        }
    }

    return (
        <div>
            <div className='ttp-input-title'>
                created...<br />
                tournament_id: {props.tournament.tournament_id} <br />
                parent_id: {props.tournament.parent_id} <br />
                name: {props.tournament.name} <br />
                datetime: {local_datetime} <br />
                max_players: {props.tournament.max_players} <br />
                max_seats: {props.tournament.max_seats} <br />
                buy_in: {props.tournament.buy_in} <br />
                small_blind: {props.tournament.small_blind} <br />
                big_blind: {props.tournament.big_blind} <br />
                Time before start: {deadline} <br />
                {connecting && tables.length === 0 && 'connecting...'}
                {tables.length > 0 &&
                    <button className="ttp-button" onClick={finish_tournament}>
                        finish
                    </button>
                }
            </div>
            {
                tables.map((table, index) => {
                    return (
                        <TableTestComponent key={index} room_id={table.room_id} subscriptions={table.subscriptions} />
                    );
                })
            }
        </div>
    );
}