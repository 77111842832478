class Settings {
    voice_chat_server_url = 'wss://prod.hmstrs.com:4443';
    //text_chat_server_url = 'wss://localhost:4003';
    text_chat_server_url = 'wss://prod.hmstrs.com:4003';
    //tournaments_server_url = 'https://localhost:4001';
    tournaments_server_url = 'https://prod.hmstrs.com:4001';
    //tournaments_socket_url = 'wss://localhost:4001';
    tournaments_socket_url = 'wss://prod.hmstrs.com:4001';
    //accounts_server_url = 'https://localhost:4000';
    accounts_server_url = 'https://prod.hmstrs.com:4000';
    
    //magic_public_key = 'pk_live_BC832560A40A8DC8';

    claimr_url = 'https://claimr.io';
    //claimr_url = 'https://localhost:3012';
    //claimr_url = 'https://main.dcy8dsa7plbnd.amplifyapp.com';

    twitter_client_id = 'dm9BXzBSRGZNZHZCUlRoYVhGUzc6MTpjaQ';
    twitch_client_id = 'gok71es467oqvyr4uf8zd5s407wbdh';
    discord_client_id = '1005437966698692649';
    hmstrs_client_id = 'cYxH2Yg1i8kFt97XbQdX';

    twitter_redirect_uri = 'auth/twitter';
    twitch_redirect_uri = 'auth/twitch';
    discord_redirect_uri = 'auth/discord';
    hmstrs_redirect_uri = 'auth/hmstrs';
}

export default new Settings();