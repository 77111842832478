//import detectEthereumProvider from '@metamask/detect-provider';
import { useEffect, useState } from 'react';
import './metamask_bar.css'

export function MetamaskBar() {
    const [has_etherium, set_has_etherium] = useState(false);
    const [selected_address, set_selected_address] = useState(null);

    useEffect(() => {
        //handle_provider();
        if (window.ethereum) {
            handle_ethereum();
        } else {
            window.addEventListener('ethereum#initialized', handle_ethereum, {
                once: true,
            });
            setTimeout(handle_ethereum, 3000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*const handle_provider = async () => {
        const provider: any = await detectEthereumProvider();

        if (provider) {
            set_has_etherium(true);
            if (provider.selectedAddress) {
                set_selected_address(provider.selectedAddress);
            } else {
                provider
                    .request({ method: 'eth_accounts' })
                    .then(handle_account_changed)
                    .catch((err: any) => {
                        // Some unexpected error.
                        // For backwards compatibility reasons, if no accounts are available,
                        // eth_accounts will return an empty array.
                        console.error(err);
                    });
            }
            provider.on('accountsChanged', handle_account_changed);
        }
    }*/

    const handle_ethereum = () => {
        console.log(window.ethereum);
        set_has_etherium(true);
        if (window.ethereum.selectedAddress) {
            set_selected_address(window.ethereum.selectedAddress);
        } else {
            window.ethereum
                //.request({ method: 'eth_accounts' })
                .request({ method: 'eth_requestAccounts' })
                .then(handle_account_changed)
                .catch((err: any) => {
                    console.error(err);
                });
        }
        window.ethereum.on('accountsChanged', handle_account_changed);
    }

    const handle_account_changed = (accounts: any[]) => {
        // Note that this event is emitted on page load.
        // If the array of accounts is non-empty, you're already
        // connected.

        // For now, 'eth_accounts' will continue to always return an array
        if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts
            console.log('Please connect to MetaMask.');
        } else if (accounts[0] !== selected_address) {
            set_selected_address(accounts[0]);
            // Do any other work!
        }
    }

    const on_connect = async () => {
        //const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        //set_selected_address(accounts[0]);
        handle_ethereum();
    }

    return (
        <div className='mm-root'>
            metamask
            {has_etherium ?
                <div>
                    {
                        selected_address ?
                            <div>
                                {selected_address}
                            </div>
                            :
                            <button className='mm-button' onClick={on_connect}>
                                connect
                            </button>
                    }
                </div>
                :
                <div>
                    Disconnected
                </div>
            }
        </div>
    );
}