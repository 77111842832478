import { useState } from "react";
import { post_json } from "../../core/fetch";
import { sha256 } from "../../core/hash";
import settings from "../../core/settings";
import { useAccountStore } from "../../stores/account_store";
import './dev_login_page.css'

export function DevLoginPage() {
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');
    const set_access_token = useAccountStore(state => state.set_access_token);
    const fetch_account = useAccountStore(state => state.fetch_account);

    const on_login = async () => {
        post_json(settings.accounts_server_url + '/api/auth/login', {
            address: email,
            password: await sha256(password),
        }).then(data => {
            console.log('/api/login', data);
            if (data.status === 200) {
                set_access_token(data.token);
                fetch_account();
            }
        });
    }

    return (
        <div className="dlp-root">
            <div className="dlp-title">
                LOGIN
            </div>
            <div>
                email:
                <input className='dlp-input' id='dev-account-email' type="text" value={email} onChange={event => set_email(event.target.value.toLowerCase())} />
                address:
                <input className='dlp-input' id='dev-account-password' type="password" value={password} onChange={event => set_password(event.target.value)} />
            </div>
            <div>
                <button className="dlp-button" onClick={on_login} disabled={email === '' || password === ''}>
                    login
                </button>
            </div>
        </div>
    );
}