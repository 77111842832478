import { ethers } from "ethers";

class Eth {
    provider: any = null;
    constructor() {
        try {
            this.provider = new ethers.providers.Web3Provider(window.ethereum);
        } catch (err) {
            console.log('Failed tp create provider', err);
        }
    }
}

export default new Eth();