import { useEffect, useState } from "react";
import { post_json } from "../../core/fetch";
import settings from "../../core/settings";
import storage from "../../core/storage";
import { SubscriptionStateComponent } from "../dev/tournaments/subsctiption_state_component";
import * as events from '../../core/events'
import './tournament_result_component.css'

interface Props {
    tournament_id: string;
    account_id: string;
}

interface Table {
    room_id: string;
    opened: boolean;
    players: any[];
}

let tables_storage: Table[] = [];

export function TournamentResultComponent(props: Props) {
    //const [tournament, set_tournament] = useState<any>(null);
    const [subscriptions, set_subscriptions] = useState<any[]>([]);
    const [tables, set_tables] = useState<Table[]>([]);

    useEffect(() => {
        //fetch_tournament();
        fetch_subscriptions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!subscriptions)
            return;

        let rooms: any = {};
        for (let subscription of subscriptions) {
            const room_id = subscription.room_id.S;
            if (room_id !== '') {
                if (!rooms[room_id]) {
                    rooms[room_id] = {
                        opened: false,
                        players: [],
                    };
                }
                rooms[room_id].players.push(subscription);
                if (subscription.current_state.S === 'booked') {
                    rooms[room_id].opened = true;
                }
            }
        }

        let new_tables = [];
        for (let room_id in rooms) {
            let room = rooms[room_id];
            let players: any[] = [];
            let table = {
                room_id,
                opened: room.opened,
                players,
            };
            for (let subscription of room.players) {
                table.players.push({
                    name: 'fetching...',
                    account_id: subscription.account_id.S,
                    avatar: '',
                    state: subscription.current_state.S,
                });
            }
            new_tables.push(table);
        }
        tables_storage = new_tables;

        set_tables([...new_tables]);
        fetch_players_info();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptions]);

    const fetch_players_info = () => {
        for (let table of tables_storage) {
            for (let player of table.players) {
                fetch_account(player.account_id);
            }
        }
    }

    /*const fetch_tournament = () => {
        post_json(settings.tournaments_server_url + '/tournament', {
            tournament_id: props.tournament_id
        }, storage.jwt).then(data => {
            console.log('fetch_tournament', data);
            if (data.status === 200) {
                if (data.data) {
                    set_tournament(data.data);
                }
            }
        });
    }*/

    const fetch_subscriptions = () => {
        post_json(settings.tournaments_server_url + '/subscriptions', {
            tournament_id: props.tournament_id
        }, storage.jwt).then(data => {
            //console.log('fetch_subscriptions', data);
            if (data.status === 200) {
                if (data.data) {
                    set_subscriptions(data.data);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const fetch_account = (account_id: string) => {
        post_json(settings.accounts_server_url + '/account', {
            account_id
        }).then(data => {
            //console.log('fetch_account', data);
            if (data.status === 200) {
                if (data.data) {
                    set_player_info(account_id, data.data);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const set_player_info = (account_id: string, account: any) => {
        for (let table of tables_storage) {
            for (let player of table.players) {
                if (player.account_id === account_id) {
                    player.name = account.name.S;
                    player.avatar = account.small_avatar.S;
                }
            }
        }
        set_tables([...tables_storage]);
    }

    const on_observe = (room_id: string) => {
        events.emit('room:observe', { room_id });
    }

    return (
        <div>
            <div className="trc-title-text">
                results:
            </div>
            <div className="trc-scroll">
                {
                    tables.map((table, index) => {
                        return (
                            <div key={index}>
                                <div className="trc-room-text">
                                    room - {index + 1}
                                </div>
                                {table.opened && <div className="trc-room-text">
                                    <button className="trc-button" onClick={() => on_observe(table.room_id)}>
                                        <div className="trc-button-text">
                                            OBSERVE
                                        </div>
                                    </button>
                                </div>}
                                {
                                    table.players.map((player, index) => {
                                        return (
                                            <div className="trc-player-line" key={index}>
                                                {
                                                    player.avatar !== '' &&
                                                    <img className='trc-account-icon' alt='avatar' src={player.avatar} />
                                                }
                                                <span className="trc-player-text">
                                                    {player.name + ' ............ '}
                                                    <SubscriptionStateComponent state={player.state} />
                                                </span>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}