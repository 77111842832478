import { useEffect, useState } from "react";
import { get_json, post_json } from "../../core/fetch";
import settings from "../../core/settings";
import storage from "../../core/storage";
import { useSessionStore } from "../../stores/session_store";
import { VoiceChatOverlay } from "../../voice_chat/voice_chat_overlay";
import * as events from '../../core/events'
import { TournamentTimerComponent } from "./tournament_timer_component";
import { TournamentResultComponent } from "./tournament_result_component";
import TournamentIFrame from "./tournament_iframe";
import { Connection } from "../../core/connection";
import { TextChatOverlay } from "../text_chat/text_chat_overlay";
import { CardsHistoryOverlay } from "../cards_history/cards_history_overlay";
import { useNavigate } from "react-router-dom";
import { TournamentProfileComponent } from "./tournament_profile_component";
import './tournament_component.css'

enum PlayerState {
    fetching_tournament = 0,
    tournament_fetched,
    not_subscribed,
    waiting_for_event,
    skipped_event,
    started_event,
    observe_event,
    completed_event,
    won_event,
}

interface Props {
    tournament_id: string;
}

let connection: Connection = new Connection();

export function TournamentComponent(props: Props) {
    const account_id = useSessionStore(state => state.account_id);
    const username = useSessionStore(state => state.username);
    const small_avatar = useSessionStore(state => state.small_avatar);
    const [tournament, set_tournament] = useState<any>(null);
    const [subscription, set_subscription] = useState<any>(null);
    const [player_state, set_player_state] = useState(PlayerState.fetching_tournament);
    const [room_id, set_room_id] = useState('');
    const [local_datetime, set_local_datetime] = useState('');
    const [players, set_players] = useState<any[]>([]);

    let navigate = useNavigate();

    useEffect(() => {
        connection.prefix = 'tours:';

        fetch_tournament();

        events.on('player:state', on_player_state_event);
        events.on('net:tours:clients:created', on_connect_event);
        events.on('net:tours:clients:list', on_list_event);
        events.on('net:tours:tournament:start', on_join);
        events.on('room:observe', on_observe);
        return () => {
            events.off('player:state', on_player_state_event);
            events.off('net:tours:clients:created', on_connect_event);
            events.off('net:tours:clients:list', on_list_event);
            events.off('net:tours:tournament:start', on_join);
            events.off('room:observe', on_observe);
            connection.destroy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!tournament)
            return;

        const date = new Date(tournament.datetime.S);
        set_local_datetime(date.toLocaleString());

        if (player_state === PlayerState.won_event) {
            //set_player_state(PlayerState.completed_event);
            if (tournament.next_id && tournament.next_id.S !== '') {
                navigate('/tournament/' + tournament.next_id.S, { replace: true });
                window.location.reload();
                //const win: any = window.open('/tournament/' + tournament.next_id.S, '_blank');
                //win.focus();
            }
        }

        if (tournament.current_state.S === 'completed') {
            set_player_state(PlayerState.completed_event);
        } else {
            connection.init(settings.tournaments_socket_url);
            set_player_state(PlayerState.tournament_fetched);
            fetch_subscription();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournament]);

    useEffect(() => {
        if (!subscription)
            return;

        useSessionStore.setState({
            room_id: subscription.room_id.S,
            tournament_id: props.tournament_id,
            subscription_id: subscription.subscription_id.S,
            invitation_key: subscription.invitation_key.S,
        });

        if (subscription.current_state.S === 'won' || subscription.current_state.S === 'lost' || subscription.current_state.S === 'left') {
            set_player_state(PlayerState.completed_event);
            return;
        }

        if (tournament.current_state.S !== 'closed' && subscription.invitation_key.S !== '') {
            set_player_state(PlayerState.started_event);
            return;
        }

        if (tournament.current_state.S !== 'created') {
            set_player_state(PlayerState.skipped_event);
            return;
        }

        set_player_state(PlayerState.waiting_for_event);

        const delta = get_delta();
        if (delta > 0) {
            //const connect_timeout_id = setTimeout(on_connect, delta);
            //const join_timeout_id = setTimeout(on_join, delta + 5000);

            return () => {
                //clearTimeout(connect_timeout_id);
                //clearTimeout(join_timeout_id);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription]);

    const fetch_tournament = () => {
        get_json(`${settings.tournaments_server_url}/tournament?tid=${props.tournament_id}`, storage.jwt).then(data => {
            //console.log('fetch_tournament', data);
            if (data.status === 200) {
                if (data.data) {
                    set_tournament(data.data);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const fetch_subscription = () => {
        get_json(`${settings.tournaments_server_url}/subscription?tid=${props.tournament_id}&aid=${account_id}`, storage.jwt).then(data => {
            //console.log('fetch_subscription', data);
            if (data.status === 200) {
                if (data.data) {
                    set_subscription(data.data);
                } else {
                    if (tournament && tournament.current_state.S === 'created') {
                        if (tournament.is_public && tournament.is_public.BOOL) {
                            on_subscribe();
                        } else {
                            set_player_state(PlayerState.not_subscribed);
                        }
                    } else {
                        set_player_state(PlayerState.skipped_event);
                    }
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const get_delta = () => {
        const now = Date.now();
        const dl = new Date(tournament.datetime.S).getTime();
        return dl - now;
    }

    const on_player_state_event = (data: any) => {
        setTimeout(() => {
            set_player_state(data === 'won' ? PlayerState.won_event : PlayerState.completed_event);
            fetch_tournament();
        }, 5000);
    }

    const on_connect_event = (data: any) => {
        connection.send({
            command: 'client:info',
            data: {
                tournament_id: props.tournament_id,
                account_id,
                username,
                small_avatar,
            },
            sender_id: data.client_id,
            tournament_id: props.tournament_id,
            context: 'system',
        });
    }

    const on_list_event = (data: any) => {
        //console.log(data);
        set_players(data);
    }

    const on_subscribe = () => {
        set_player_state(PlayerState.tournament_fetched);
        post_json(settings.tournaments_server_url + '/create_subscription', {
            account_id,
            tournament_id: props.tournament_id,
        }, storage.jwt).then(data => {
            //console.log('create_subscription', data);
            fetch_subscription();
        });
    }

    /*const on_unsubscribe = () => {
        set_player_state(PlayerState.not_subscribed);
        set_subscription(null);
        post_json(settings.tournaments_server_url + '/delete_subscription', {
            subscription_id: subscription.subscription_id.S
        }, storage.jwt).then(data => {
            fetch_subscription();
        });
    }*/

    /*const on_connect = () => {
        set_player_state(PlayerState.connecting);
    }*/

    const on_join = () => {
        //console.log('on_join');
        fetch_subscription();
    }

    const on_observe = (data: any) => {
        set_room_id(data.room_id);
        set_player_state(PlayerState.observe_event);
    }

    return (
        <div>
            {player_state === PlayerState.fetching_tournament &&
                <>
                    <div className='tc-blue-status-text'>
                        fetching tournament info
                    </div>
                    <TournamentProfileComponent />
                </>
            }
            {player_state === PlayerState.tournament_fetched &&
                <div className='tc-blue-status-text'>
                    fetching subscription info
                </div>
            }
            {player_state === PlayerState.not_subscribed &&
                <div>
                    <div className='tc-blue-date-text'>
                        {local_datetime}
                    </div>
                    {/*
                    <button className={get_style('button')} onClick={on_subscribe}>
                        <div className={get_style('button-text')}>
                            JOIN THE TOURNAMENT
                        </div>
                    </button>
                    */}
                </div>
            }
            {player_state === PlayerState.waiting_for_event &&
                <>
                    <div className='tc-blue-status-text'>
                        <div className='tc-blue-status-text'>
                            You’re in!
                        </div>
                        <TournamentTimerComponent datetime={tournament.datetime.S} />
                    </div>
                    <TournamentProfileComponent />

                    <div className='tc-blue-status-text'>
                        Players waiting to start:
                    </div>
                    {
                        players.map((player, index) => {
                            return (
                                <div className="trc-player-line" key={index}>
                                    {player.username &&
                                        <>
                                            {
                                                player.small_avatar !== '' &&
                                                <img className='trc-account-icon' alt='avatar' src={player.small_avatar} />
                                            }
                                            <span className="trc-player-text">
                                                {player.username}
                                            </span>
                                        </>
                                    }
                                </div>
                            );
                        })
                    }
                    <TextChatOverlay
                        account_id={subscription.account_id.S}
                        room_id={subscription.tournament_id.S} />

                    {/*
                    <button className={get_style('button')} onClick={on_unsubscribe}>
                        <div className={get_style('button-text')}>
                            CANCEL
                        </div>
                    </button>
                    */}
                </>
            }
            {/*player_state === PlayerState.connecting &&
                <div className='tc-status-text'>
                    connecting...
                </div>
            */}
            {player_state === PlayerState.skipped_event &&
                <div className='tc-blue-status-text'>
                    tournament already started
                    <div>
                        <TournamentResultComponent tournament_id={props.tournament_id} account_id={account_id} />
                    </div>
                </div>
            }
            {player_state === PlayerState.started_event &&
                <div>
                    <TournamentIFrame
                        account_id={subscription.account_id.S}
                        room_id={subscription.room_id.S}
                        invitation_key={subscription.invitation_key.S}
                        subscription_id={subscription.subscription_id.S}
                        tournament_id={props.tournament_id} />
                    <VoiceChatOverlay room_id={subscription.room_id.S} />
                    <TextChatOverlay
                        account_id={subscription.account_id.S}
                        room_id={subscription.room_id.S} />
                    <CardsHistoryOverlay />
                </div>
            }
            {player_state === PlayerState.observe_event &&
                <div>
                    <TournamentIFrame
                        account_id={account_id}
                        room_id={room_id}
                        invitation_key=''
                        subscription_id=''
                        tournament_id={props.tournament_id} />
                </div>
            }
            {player_state === PlayerState.completed_event &&
                <div>
                    <TournamentResultComponent tournament_id={props.tournament_id} account_id={account_id} />
                </div>
            }
            {player_state === PlayerState.won_event &&
                <div className='tc-blue-status-text'>
                    Congratulations!!!<br /><br />
                    You won this round<br /><br />
                    Redirecting to next one, please wait...
                </div>
            }
        </div>
    );
}