import * as events from './events'

export class Connection {
    _socket: any = null;
    _url: string = '';

    prefix: string = '';

    init(url: string) {
        //console.log(`Connection:init ${url}`);
        this._url = url;
        this._create_socket();
    }

    destroy() {
        if (this._socket) {
            //console.log(`Connection:destroy ${this._url}`);
            this._socket.onclose = null;
            this._socket.close();
            this._socket = null;
        }
    }

    send(data: any) {
        this._socket.send(JSON.stringify(data));
    }

    _create_socket() {
        this.destroy();
        this._socket = new WebSocket(this._url);

        this._socket.onopen = function () {
            console.log('onopen');
        };

        this._socket.onmessage = this._on_message.bind(this);
        this._socket.onclose = this._on_close.bind(this);
        this._socket.onerror = this._on_error.bind(this);
    }

    _on_message(e: any) {
        var message = JSON.parse(e.data);
        //console.log(message);
        this._handle_message(message);
    }

    _on_error(event: any) {
        console.log('onerror', event);
        this._socket.close();
    }

    _on_close() {
        var self = this;
        console.log('onclose');
        setTimeout(function () {
            self._create_socket();
        }, 1000);
    }

    _handle_message(message: any) {
        events.emit('net:' + this.prefix + message.command, message.data);
    }
}