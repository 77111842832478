import create from "zustand";
import * as events from '../core/events'

export interface IRoomStore {
  set_status: (state: string) => void;
  set_error: (error: string) => void;
  set_muted: (value: boolean) => void;
  status: string;
  error: string;
  muted: boolean;
};


export const useRoomStore = create<IRoomStore>((set) => {
  const set_status = (status: string) => {
    set((store) => {
      store.status = status;
    })
  }

  const set_error = (error: string) => {
    set((store) => {
      store.error = error;
    })
  }

  const set_muted = (value: boolean) => {
    set((store) => {
      store.muted = value;
    })
  }

  events.on('room:state', (data) => {
    console.log('on room:state', data);
    set_status(data);
  });

  events.on('room:error', (data) => {
    console.log('on room:error', data);
    set_error(data);
  });

  return {
    set_status,
    set_error,
    set_muted,
    status: 'disconnected',
    error: '',
    muted: false,
  }
})
