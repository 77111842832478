import { useEffect } from "react";
import { useWalletStore } from "../stores/wallet_store";
import { ethers } from "ethers";
import { post_json } from "../core/fetch";
import settings from "../core/settings";
import { useSessionStore } from "../stores/session_store";

import './meta_mask_login.css';
import storage from "../core/storage";

let provider: any = null;

export function MetaMaskLogin() {
    useEffect(() => {
        connect_provider();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const connect_provider = async () => {
        provider = new ethers.providers.Web3Provider(window.ethereum);
        console.log('provider', provider);

        const address = provider.provider.selectedAddress;
        console.log('address', address);

        if (address) {
            login(address);
        }
    }

    const login = async (address: string) => {
        const ens_name = await provider.lookupAddress(address);
        console.log(ens_name);

        const signer = provider.getSigner();
        const signature = await signer.signMessage(address);
        post_json(settings.accounts_server_url + '/signup', {
            address,
            signature
        }).then(data => {
            console.log('login', data);
            useWalletStore.setState({
                address,
            });
            useSessionStore.setState({
                account_id: data.data,
            });
            storage.set_jwt(data.token);
        });
    }

    const login_ethers = async () => {
        await provider.send("eth_requestAccounts", []);
        if (provider.provider.selectedAddress) {
            await login(provider.provider.selectedAddress);
        }
    }

    return (
        <button className="mml-button" onClick={login_ethers}>
            MetaMask Login
        </button>
    );
}