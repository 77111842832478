export function post_json(url: string, body: any, jwt: string | null = null): Promise<any> {
    let requestOptions: any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    };
    if (jwt) {
        requestOptions.headers['Authorization'] = 'Bearer ' + jwt;
    }
    return fetch(url, requestOptions)
        .then(response => response.json());
}

export function get_json(url: string, jwt: string | null = null): Promise<any> {
    let requestOptions: any = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    if (jwt) {
        requestOptions.headers['Authorization'] = 'Bearer ' + jwt;
    }
    return fetch(url, requestOptions)
        .then(response => response.json());
}