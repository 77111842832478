import { useState } from "react";
import { post_json } from "../../core/fetch";
import { sha256 } from "../../core/hash";
import settings from "../../core/settings";
import { validate_email } from "../../core/utils";
import { useAccountStore } from "../../stores/account_store";
import { OAuth2RecoverDialog } from "./oauth2_recover_dialog";
import { OAuth2SignupComponent } from "./oauth2_signup_component";

export function OAuth2LoginComponent() {
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');
    const [error, set_error] = useState('');
    const [recovering, set_recovering] = useState(false);
    const [registering, set_registering] = useState(false);
    const set_access_token = useAccountStore(state => state.set_access_token)
    const fetch_account = useAccountStore(state => state.fetch_account);

    const on_login = async () => {
        post_json(settings.accounts_server_url + '/api/auth/login', {
            address: email,
            password: await sha256(password),
        }).then(data => {
            //console.log('/api/login', data);
            if (data.status === 200) {
                set_access_token(data.token);
                fetch_account();
            } else {
                set_error('INVALID PASSWORD OR ACCOUNT NOT FOUND');
            }
        });
    }

    const on_recover = () => {
        set_recovering(true);
    }

    const close_dialog = () => {
        set_recovering(false);
    }

    const on_register = () => {
        set_registering(true);
    }

    const on_cancel_register = () => {
        set_registering(false);
    }

    return (
        <div>
            {
                registering ?
                    <div>
                        <OAuth2SignupComponent on_close={on_cancel_register} />
                    </div>
                    :
                    <div>
                        <div>
                            <div className='oap-big-text'>
                                Please login to continue
                            </div>
                            <div className='oap-text'>
                                E-mail
                            </div>
                            <div>
                                <input className={validate_email(email) ? 'oap-input' : 'oap-input-error'} id="email" type="text" value={email} onChange={event => set_email(event.target.value.toLowerCase())} />
                            </div>
                            <div className='oap-text'>
                                Password
                            </div>
                            <div>
                                <input className='oap-input' id="password" type="password" value={password} onChange={event => set_password(event.target.value)} />
                            </div>
                            <div className='oap-error'>
                                {error}
                            </div>
                            <div>
                                <button className='oap-button' onClick={on_login} disabled={!validate_email(email) || password === ''}>
                                    Login
                                </button>
                                <button className='oap-button' onClick={on_recover}>
                                    Forgot Password
                                </button>
                                <button className='oap-button' onClick={on_register}>
                                    Register New Account
                                </button>
                            </div>
                        </div>
                        {recovering && <OAuth2RecoverDialog on_close={close_dialog} />}
                    </div>
            }

        </div>
    );
}