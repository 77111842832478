export const doge_avatars = [
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/doge_gradient-0.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-0.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/doge_gradient-1.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-coldplunge.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/doge_gradient-2.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-coldplunge.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/doge_gradient-3.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-coldplunge.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/doge_gradient-4.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-coldplunge.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/doge_gradient-5.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-coldplunge.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/doge_gradient-6.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-coldplunge.png',
    },
]