import { useEffect } from 'react';
import './p2p_test_page.css'
/*
import { io } from "socket.io-client";

var p2p = require('socket.io-p2p');

//var P2P = require('socket.io-p2p');
//var io = require('socket.io-client');
//var socket = io("https://server-domain.com");

//var p2p = new P2P(socket);

var opts = { numClients: 10 };

const socket = io("https://localhost:5000");
//const socket = io("https://prod.hmstrs.com:5000");
const p2p_socket = new p2p(socket, opts, function (data: any) {
    console.log('We all speak WebRTC now', data);
});
socket.on("connect", () => {
    //p2p_socket.upgrade();
    console.log(socket.id);
});

p2p_socket.on('ready', function () {
    console.log('ready', p2p_socket.peerId);
    p2p_socket.usePeerConnection = true;
    p2p_socket.emit('peer-obj', { peerId: p2p_socket.peerId });
})

p2p_socket.on('peer-msg', function (data: any) {
    console.log(data);
});
*/
export function P2PTestPage() {
    useEffect(() => {
    }, []);

    return (
        <div className='p2ptp-root'>
            P2P Test Page
        </div>
    );
}