import './oauth2_authorize_page.css'
import { useAccountStore } from '../../stores/account_store';
import { OAuth2LoginComponent } from './oauth2_login_component';
import { OAuth2PromtComponent } from './oauth2_promt_component';

export function OAuth2AuthorizePage() {
    const access_token = useAccountStore(state => state.access_token);

    return (
        <div className='oap-root'>
            <div className='oap-title-0'>
                HMSTRS
            </div>
            <div className='oap-divider'/>
            <div className='oap-big-space'/>
            {
                access_token === '' ?
                    <OAuth2LoginComponent />
                    :
                    <OAuth2PromtComponent />
            }
        </div>
    );
}