import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import eth from '../../core/eth';
import { get_json } from '../../core/fetch';
import settings from '../../core/settings';

interface Props {
    on_close: any;
}

export function OAuth2PFPDialog(props: Props) {
    const [wallet, set_wallet] = useState('');
    const [metamask_enabled, set_metamask_enabled] = useState(true);
    const [avatars, set_avatars] = useState<any[]>([]);
    const [avatar_index, set_avatar_index] = useState<number>(0);

    useEffect(() => {
        check_provider();
    }, []);

    const valid_address = (address: string) => {
        return ethers.utils.isAddress(wallet);
    }

    useEffect(() => {
        if (ethers.utils.isAddress(wallet)) {
            load_nfts(wallet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet]);

    const check_provider = async () => {
        if (!eth.provider) {
            set_metamask_enabled(false);
            return;
        }

        const address = eth.provider.provider.selectedAddress;

        if (address) {
            set_wallet(address);
        }
    }

    const login_ethers = async () => {
        try {
            await eth.provider.send("eth_requestAccounts", []);
            if (eth.provider.provider.selectedAddress) {
                set_wallet(eth.provider.provider.selectedAddress);
            }
        } catch (err) {
            console.log(`failed to login with error: ${err}`);
        }
    }

    const load_nfts = (address: string) => {
        get_json(`${settings.accounts_server_url}/nfts?address=${address}&offset=0&limit=20`)
            .then(data => {
                //console.log('nfts', data);
                if (data.data.status === 200) {
                    if (data.data.data && data.data.data.assets && data.data.data.assets.length > 0) {
                        let new_avatars = [...avatars];
                        for (let entry of data.data.data.assets) {
                            const index = avatars.findIndex((entry) => {
                                return entry.small === entry.image_url;
                            })
                            if (index < 0) {
                                new_avatars.push({
                                    small: entry.image_url,
                                    sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-3.png',
                                });
                            }
                        }
                        set_avatars(new_avatars);
                        set_avatar_index(new_avatars.length - 1);
                    }
                }
            });
    }

    const on_ok = () => {
        props.on_close(avatars[avatar_index]);
    }

    const on_cancel = () => {
        props.on_close(null);
    }

    const on_connect_wallet = () => {
        login_ethers();
    }

    return (
        <div className='oap-overlay'>
            <div className='oap-dialog'>
                <div className='oap-title-1'>
                    Upload Own NFT
                </div>
                <div className='oap-big-space' />
                <div className='oap-text'>
                    Enter Your Wallet Address
                </div>
                <div>
                    <input className={valid_address(wallet) ? 'oap-input' : 'oap-input-error'} id="wallet" type="text" value={wallet} onChange={event => set_wallet(event.target.value)} />
                </div>
                <div className='oap-text'>
                    OR
                </div>
                <div>
                    <button className='oap-button' onClick={on_connect_wallet} disabled={!metamask_enabled}>
                        CONNECT WALLET
                    </button>
                </div>

                <div className='oap-big-space' />
                <div className='oap-avatars-center'>
                    <div className='oap-avatars'>
                        {avatars.map((value, index) => {
                            return <div className={index === avatar_index ? 'oap-avatar-selected' : 'oap-avatar'} key={index}>
                                <img className='oap-avatar-image' alt='avatar' src={value.small} onClick={() => set_avatar_index(index)} />
                            </div>
                        })}
                    </div>
                </div>

                <div className='oap-big-space' />
                <div>
                    <button className='oap-button' onClick={on_ok} disabled={avatar_index >= avatars.length}>
                        Ok
                    </button>
                    <button className='oap-button' onClick={on_cancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}