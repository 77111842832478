import * as events from '../core/events'
import create from "zustand";

export interface IPeer {
  id: string;
  name: string;
  info: any;
  consumer: any;
}

export interface IPeersStore {
  peers: IPeer[];
}

export const usePeersStore = create<IPeersStore>((set) => {

  const add_peer = (peer_id: string, name: string, info: any) => {
    set(state => ({
      peers: [
        ...state.peers,
        {
          id: peer_id,
          name,
          info,
          consumer: null,
        } as IPeer,
      ]
    }));
  }

  const set_name = (peer_id: string, name: string) => {
    set(state => ({
      peers: state.peers.map((peer) =>
        peer.id === peer_id
          ? ({ ...peer, name } as IPeer)
          : peer
      ),
    }))
  }

  const remove_peer = (peer_id: string) => {
    set((state) => ({
      peers: state.peers.filter((peer) => peer.id !== peer_id),
    }));
  }

  const add_consumer = (peer_id: string, consumer: any) => {
    set(state => ({
      peers: state.peers.map((peer) =>
        peer.id === peer_id
          ? ({ ...peer, consumer } as IPeer)
          : peer
      ),
    }))
  }

  const remove_consumer = (peer_id: string) => {
    set(state => ({
      peers: state.peers.map((peer) =>
        peer.id === peer_id
          ? ({ ...peer, consumer: null } as IPeer)
          : peer
      ),
    }))
  }

  const clear = () => {
    set(state => ({
      peers: [],
    }))
  }

  events.on('peers:add', (data) => {
    console.log('on peers:add', data);
    add_peer(data.peer.id, data.peer.displayName, data.peer);
  });

  events.on('peers:remove', (data) => {
    console.log('on peers:remove', data);
    remove_peer(data.peer_id);
  });

  events.on('peers:clear', () => {
    console.log('on peers:clear');
    clear();
  });

  events.on('consumers:add', (data) => {
    console.log('on consumers:add', data);
    add_consumer(data.peerId, data.consumer);
  });

  events.on('consumers:remove', (data) => {
    console.log('on consumers:remove', data);
    remove_consumer(data.peer_id);
  });

  return {
    add_peer,
    set_name,
    remove_peer,
    add_consumer,
    remove_consumer,
    clear,
    peers: [],
  }
})