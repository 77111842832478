import protooClient from 'protoo-client';
import * as mediasoupClient from 'mediasoup-client';
import randomString from 'random-string';
import { DeviceInfo, device_info } from '../core/device_info';
import * as events from '../core/events'
import * as e2e from './e2e';
import UrlParse from 'url-parse';
import settings from '../core/settings';

const PC_PROPRIETARY_CONSTRAINTS =
{
    optional: [{ googDscp: true }]
};

export class VoiceChatClient {
    public connected: boolean;

    private _closed: boolean;
    private _displayName: any;
    private _roomId: string;
    private _peerId: string;
    private _forceTcp: boolean;
    private _device: DeviceInfo;
    private _handlerName: any = null;
    private _protooUrl: string;

    private _protoo: any;//protooClient.Peer;
    private _mediasoupDevice: any;//mediasoupClient.Device;
    private _sendTransport: any;//mediasoupClient.Transport
    private _recvTransport: any;//mediasoupClient.Transport
    private _micProducer: any;//mediasoupClient.Producer

    private _consumers: any;//Map<string, mediasoupClient.Consumer>;
    
    //private _useDataChannel: boolean;
    //private _dataConsumers: any;//Map<string, mediasoupClient.DataConsumer>;
    //private _nextDataChannelTestNumber: number;

    private _e2eKey: any;

    private _gain_node: any;

    get_protoo_url(): string {
        return `${settings.voice_chat_server_url}/?roomId=${this._roomId}&peerId=${this._peerId}`;
    }

    constructor(display_name: string, room_id: string) {
        this.connected = false;

        this._peerId = randomString({ length: 8 }).toLowerCase();
        this._roomId = room_id;
        this._device = device_info();
        this._displayName = display_name;
        this._protooUrl = this.get_protoo_url();

        console.log(
            'constructor() [roomId:"%s", peerId:"%s", device:%s, url:"%s"]',
            this._roomId, this._peerId, this._device.flag, this._protooUrl);

        const urlParser = new UrlParse(window.location.href, true);
        const e2eKey = urlParser.query.e2eKey;

        this._forceTcp = false;
        this._closed = false;
        this._handlerName = null;

        this._protoo = null;
        this._mediasoupDevice = null;
        this._sendTransport = null;
        this._recvTransport = null;

        this._micProducer = null;

        this._gain_node = null;

        this._consumers = new Map();

        //this._useDataChannel = true;
        //this._dataConsumers = new Map();
		//this._nextDataChannelTestNumber = 0;
		
        this._e2eKey = e2eKey;

        if (this._e2eKey && e2e.isSupported()) {
			e2e.setCryptoKey('setCryptoKey', this._e2eKey, true);
		}
    }

    _add_consumer(consumer: any, peer_id: string) {
        console.log('Add Consumer', peer_id, consumer);
        events.emit('consumers:add', { peer_id, consumer });
    }

    _remove_consumer(consumer_id: string, peer_id: string) {
        console.log('Remove Consumer', consumer_id, peer_id);
        events.emit('consumers:remove', { peer_id });
    }

    /*_add_data_consumer(consumer: any, peer_id: string) {
        console.log('Add Data Consumer', peer_id, consumer);
        //events.emit('consumers:add', { peer_id, consumer });
    }

    _remove_data_consumer(data_consumer_id: string, peer_id: string) {
        console.log('Remove Data Consumer', data_consumer_id, peer_id);
        //events.emit('consumers:remove', { peer_id });        
    }*/

    _add_peer(peer: any) {
        console.log('Add Peer', peer);
        events.emit('peers:add', { peer });
    }

    _remove_peer(peer_id: string) {
        console.log('Remove Peer', peer_id);
        events.emit('peers:remove', { peer_id });
        for(let consumer of this._consumers) {
            const { _peer_id } = consumer.appData;
            if (_peer_id === peer_id) {
                this._remove_consumer(consumer.id, _peer_id);
            }
        }
    }

    _add_producer(producer: any) {
        console.log('Add Producer', producer);
        events.emit('producers:add', { producer });
    }

    _remove_producer(producer_id: string) {
        console.log('Remove Producer', producer_id);
        events.emit('producers:remove', { producer_id });
    }

    close() {
        if (this._closed)
            return;

        this.connected = false;

        this._closed = true;

        //this.disableMic();

        console.log('close()');

        events.emit('peers:clear');
        //events.emit('producers:remove');


        this._protoo.close();

        if (this._sendTransport)
            this._sendTransport.close();

        if (this._recvTransport)
            this._recvTransport.close();

        events.emit('room:state', 'closed');
    }

    async join() {
        const protooTransport = new protooClient.WebSocketTransport(this._protooUrl);

        this._protoo = new protooClient.Peer(protooTransport);

        events.emit('room:state', 'connecting');

        this._protoo.on('open', () => this._joinRoom());

        this._protoo.on('failed', () => {
            events.emit('room:error', 'WebSocket connection failed');
        });

        this._protoo.on('disconnected', () => {
            events.emit('room:error', 'WebSocket disconnected');

            // Close mediasoup Transports.
            if (this._sendTransport) {
                this._sendTransport.close();
                this._sendTransport = null;
            }

            if (this._recvTransport) {
                this._recvTransport.close();
                this._recvTransport = null;
            }

            events.emit('room:state', 'closed');
        });

        this._protoo.on('close', () => {
            if (this._closed)
                return;

            this.close();
        });

        // eslint-disable-next-line no-unused-vars
        this._protoo.on('request', async (request: any, accept: any, reject: any) => {
            console.log(
                'proto "request" event [method:%s, data:%o]',
                request.method, request.data);

            switch (request.method) {
                case 'newConsumer':
                    {
                        const {
                            peerId,
                            producerId,
                            id,
                            kind,
                            rtpParameters,
                            type,
                            appData,
                            producerPaused
                        } = request.data;

                        try {
                            const consumer = await this._recvTransport.consume(
                                {
                                    id,
                                    producerId,
                                    kind,
                                    rtpParameters,
                                    appData: { ...appData, peerId } // Trick.
                                });

                            if (this._e2eKey && e2e.isSupported()) {
                                e2e.setupReceiverTransform(consumer.rtpReceiver);
                            }

                            // Store in the map.
                            this._consumers.set(consumer.id, consumer);

                            consumer.on('transportclose', () => {
                                this._consumers.delete(consumer.id);
                            });

                            const { spatialLayers, temporalLayers } =
                                mediasoupClient.parseScalabilityMode(
                                    consumer.rtpParameters.encodings[0].scalabilityMode);

                            const _consumer: any = {
                                id: consumer.id,
                                type: type,
                                locallyPaused: false,
                                remotelyPaused: producerPaused,
                                rtpParameters: consumer.rtpParameters,
                                spatialLayers: spatialLayers,
                                temporalLayers: temporalLayers,
                                preferredSpatialLayer: spatialLayers - 1,
                                preferredTemporalLayer: temporalLayers - 1,
                                priority: 1,
                                codec: consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
                                track: consumer.track
                            };
                            this._add_consumer(_consumer, peerId);

                            // We are ready. Answer the protoo request so the server will
                            // resume this Consumer (which was paused for now if video).
                            accept();

                            // If audio-only mode is enabled, pause it.
                            /*if (consumer.kind === 'video' && store.getState().me.audioOnly)
                                this._pauseConsumer(consumer);*/
                        }
                        catch (error) {
                            console.log('"newConsumer" request failed:%o', error);

                            events.emit('room:error', `Error creating a Consumer: ${error}`);

                            throw error;
                        }

                        break;
                    }

                /*case 'newDataConsumer':
                    {
                        if (!this._useDataChannel) {
                            reject(403, 'I do not want DataChannels');

                            break;
                        }

                        const {
                            peerId, // NOTE: Null if bot.
                            dataProducerId,
                            id,
                            sctpStreamParameters,
                            label,
                            protocol,
                            appData
                        } = request.data;

                        try {
                            const dataConsumer = await this._recvTransport.consumeData(
                                {
                                    id,
                                    dataProducerId,
                                    sctpStreamParameters,
                                    label,
                                    protocol,
                                    appData: { ...appData, peerId } // Trick.
                                });

                            // Store in the map.
                            this._dataConsumers.set(dataConsumer.id, dataConsumer);

                            dataConsumer.on('transportclose', () => {
                                this._dataConsumers.delete(dataConsumer.id);
                            });

                            dataConsumer.on('open', () => {
                                console.log('DataConsumer "open" event');
                            });

                            dataConsumer.on('close', () => {
                                console.log('DataConsumer "close" event');

                                this._dataConsumers.delete(dataConsumer.id);

                                events.emit('room:error', 'DataConsumer closed');
                            });

                            dataConsumer.on('error', (error: any) => {
                                console.log('DataConsumer "error" event:%o', error);

                                events.emit('room:error', `DataConsumer error: ${error}`);
                            });

                            dataConsumer.on('message', (message: any) => {
                                console.log(
                                    'DataConsumer "message" event [streamId:%d]',
                                    dataConsumer.sctpStreamParameters.streamId);

                                if (message instanceof ArrayBuffer) {
                                    const view = new DataView(message);
                                    const number = view.getUint32(0);

                                    if (number == Math.pow(2, 32) - 1) {
                                        console.log('dataChannelTest finished!');

                                        this._nextDataChannelTestNumber = 0;

                                        return;
                                    }

                                    if (number > this._nextDataChannelTestNumber) {
                                        console.log(
                                            'dataChannelTest: %s packets missing',
                                            number - this._nextDataChannelTestNumber);
                                    }

                                    this._nextDataChannelTestNumber = number + 1;

                                    return;
                                }
                                else if (typeof message !== 'string') {
                                    console.log('ignoring DataConsumer "message" (not a string)');

                                    return;
                                }

                                switch (dataConsumer.label) {
                                    case 'chat':
                                        {
                                            const { peers } = this._get_state();//store.getState();
                                            const peersArray = Object.keys(peers)
                                                .map((_peerId) => peers[_peerId]);
                                            const sendingPeer = peersArray
                                                .find((peer) => peer.dataConsumers.includes(dataConsumer.id));

                                            if (!sendingPeer) {
                                                console.log('DataConsumer "message" from unknown peer');

                                                break;
                                            }

                                            //store.dispatch(requestActions.notify(
                                            //    {
                                            //        title: `${sendingPeer.displayName} says:`,
                                            //        text: message,
                                            //        timeout: 5000
                                            //    }));

                                            break;
                                        }

                                    case 'bot':
                                        {
                                            //store.dispatch(requestActions.notify(
                                            //    {
                                            //        title: 'Message from Bot:',
                                            //        text: message,
                                            //        timeout: 5000
                                            //    }));

                                            break;
                                        }
                                }
                            });

                            const _consumer: any = {
                                id: dataConsumer.id,
                                sctpStreamParameters: dataConsumer.sctpStreamParameters,
                                label: dataConsumer.label,
                                protocol: dataConsumer.protocol
                            };
                            this._add_data_consumer(_consumer, peerId);
                            // We are ready. Answer the protoo request.
                            accept();
                        }
                        catch (error) {
                            console.log('"newDataConsumer" request failed:%o', error);

                            events.emit('room:error', `Error creating a DataConsumer: ${error}`);

                            throw error;
                        }

                        break;
                    }*/
            }
        });

        this._protoo.on('notification', (notification: any) => {
            if (notification.method !== 'activeSpeaker') {
                console.log(
                    'proto "notification" event [method:%s, data:%o]',
                    notification.method, notification.data);
            }

            switch (notification.method) {
                case 'producerScore':
                    {
                        //const { producerId, score } = notification.data;

                        //store.dispatch(
                        //    stateActions.setProducerScore(producerId, score));

                        break;
                    }

                case 'newPeer':
                    {
                        //const peer = notification.data;

                        this._add_peer(notification.data);

                        /*store.dispatch(requestActions.notify(
                            {
                                text: `${peer.displayName} has joined the room`
                            }));*/

                        break;
                    }

                case 'peerClosed':
                    {
                        const { peerId } = notification.data;

                        this._remove_peer(peerId);
                        //store.dispatch(
                        //    stateActions.removePeer(peerId));

                        break;
                    }

                case 'peerDisplayNameChanged':
                    {
                        //const { peerId, displayName, oldDisplayName } = notification.data;

                        //store.dispatch(
                        //    stateActions.setPeerDisplayName(displayName, peerId));

                        /*store.dispatch(requestActions.notify(
                            {
                                text: `${oldDisplayName} is now ${displayName}`
                            }));*/

                        break;
                    }

                case 'downlinkBwe':
                    {
                        console.log('\'downlinkBwe\' event:%o', notification.data);

                        break;
                    }

                case 'consumerClosed':
                    {
                        const { consumerId } = notification.data;
                        const consumer = this._consumers.get(consumerId);

                        if (!consumer)
                            break;

                        consumer.close();
                        this._consumers.delete(consumerId);

                        const { peerId } = consumer.appData;

                        this._remove_consumer(consumerId, peerId);

                        break;
                    }

                case 'consumerPaused':
                    {
                        const { consumerId } = notification.data;
                        const consumer = this._consumers.get(consumerId);

                        if (!consumer)
                            break;

                        consumer.pause();

                        //store.dispatch(
                        //    stateActions.setConsumerPaused(consumerId, 'remote'));

                        break;
                    }

                case 'consumerResumed':
                    {
                        const { consumerId } = notification.data;
                        const consumer = this._consumers.get(consumerId);

                        if (!consumer)
                            break;

                        consumer.resume();

                        //store.dispatch(
                        //    stateActions.setConsumerResumed(consumerId, 'remote'));

                        break;
                    }

                case 'consumerLayersChanged':
                    {
                        const { consumerId/*, spatialLayer, temporalLayer*/ } = notification.data;
                        const consumer = this._consumers.get(consumerId);

                        if (!consumer)
                            break;

                        //store.dispatch(stateActions.setConsumerCurrentLayers(
                        //    consumerId, spatialLayer, temporalLayer));

                        break;
                    }

                case 'consumerScore':
                    {
                        //const { consumerId, score } = notification.data;

                        //store.dispatch(
                        //    stateActions.setConsumerScore(consumerId, score));

                        break;
                    }

                /*case 'dataConsumerClosed':
                    {
                        const { dataConsumerId } = notification.data;
                        const dataConsumer = this._dataConsumers.get(dataConsumerId);

                        if (!dataConsumer)
                            break;

                        dataConsumer.close();
                        this._dataConsumers.delete(dataConsumerId);

                        const { peerId } = dataConsumer.appData;

                        this._remove_data_consumer(dataConsumerId, peerId);
                        //store.dispatch(
                        //    stateActions.removeDataConsumer(dataConsumerId, peerId));

                        break;
                    }*/

                case 'activeSpeaker':
                    {
                        //const { peerId } = notification.data;

                        //store.dispatch(
                        //    stateActions.setRoomActiveSpeaker(peerId));

                        break;
                    }

                default:
                    {
                        console.log(
                            'unknown protoo notification.method "%s"', notification.method);
                    }
            }
        });
    }

    async enableMic(device: any = null) {
        console.log('enableMic()');

        if (this._micProducer)
            return;

        if (!this._mediasoupDevice.canProduce('audio')) {
            console.log('enableMic() | cannot produce audio');

            return;
        }

        let track;

        try {
            console.log('enableMic() | calling getUserMedia()');

            const constraints = {
                audio: {deviceId: device ? {exact: device.deviceId} : undefined},
              };

            const stream = await navigator.mediaDevices.getUserMedia(constraints);

            /*const context = new AudioContext();
            const mediaStreamSource = context.createMediaStreamSource(stream);
            const mediaStreamDestination = context.createMediaStreamDestination();
            this._gain_node = context.createGain();
            mediaStreamSource.connect(this._gain_node);
            this._gain_node.connect(mediaStreamDestination);*/

            track = stream.getAudioTracks()[0];

            this._micProducer = await this._sendTransport.produce(
                {
                    track,
                    codecOptions:
                    {
                        opusStereo: 1,
                        opusDtx: 1
                    }
                    // NOTE: for testing codec selection.
                    // codec : this._mediasoupDevice.rtpCapabilities.codecs
                    // 	.find((codec) => codec.mimeType.toLowerCase() === 'audio/pcma')
                });

            if (this._e2eKey && e2e.isSupported()) {
                e2e.setupSenderTransform(this._micProducer.rtpSender);
            }

            this._add_producer({
                id: this._micProducer.id,
                paused: this._micProducer.paused,
                track: this._micProducer.track,
                rtpParameters: this._micProducer.rtpParameters,
                codec: this._micProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
            });

            this._micProducer.on('transportclose', () => {
                this._micProducer = null;
            });

            this._micProducer.on('trackended', () => {
                events.emit('room:error', 'Microphone disconnected!');

                this.disableMic()
                    .catch(() => { });
            });
        }
        catch (error) {
            console.log('enableMic() | failed:%o', error);

            events.emit('room:error', `Error enabling microphone: ${error}`);

            if (track)
                track.stop();
        }
    }

    async disableMic() {
        console.log('disableMic()');

        if (!this._micProducer)
            return;

        this._micProducer.close();

        this._remove_producer(this._micProducer.id);

        try {
            await this._protoo.request(
                'closeProducer', { producerId: this._micProducer.id });
        }
        catch (error) {
            events.emit('room:error', `Error closing server-side mic Producer: ${error}`);
        }

        this._micProducer = null;
    }

    async muteMic() {
        console.log('muteMic()');

        this._micProducer.pause();

        try {
            await this._protoo.request(
                'pauseProducer', { producerId: this._micProducer.id });

            //store.dispatch(
            //    stateActions.setProducerPaused(this._micProducer.id));
        }
        catch (error) {
            console.log('muteMic() | failed: %o', error);

            events.emit('room:error', `Error pausing server-side mic Producer: ${error}`);
        }
    }

    async unmuteMic() {
        console.log('unmuteMic()');

        this._micProducer.resume();

        try {
            await this._protoo.request(
                'resumeProducer', { producerId: this._micProducer.id });

            //store.dispatch(
            //    stateActions.setProducerResumed(this._micProducer.id));
        }
        catch (error) {
            console.log('unmuteMic() | failed: %o', error);

            events.emit('room:error', `Error resuming server-side mic Producer: ${error}`);
        }
    }

    async muteAudio() {
        console.log('muteAudio()');

        //store.dispatch(
        //    stateActions.setAudioMutedState(true));
    }

    async unmuteAudio() {
        console.log('unmuteAudio()');

        //store.dispatch(
        //    stateActions.setAudioMutedState(false));
    }

    async restartIce() {
        console.log('restartIce()');

        //store.dispatch(
        //    stateActions.setRestartIceInProgress(true));

        try {
            if (this._sendTransport) {
                const iceParameters = await this._protoo.request(
                    'restartIce',
                    { transportId: this._sendTransport.id });

                await this._sendTransport.restartIce({ iceParameters });
            }

            if (this._recvTransport) {
                const iceParameters = await this._protoo.request(
                    'restartIce',
                    { transportId: this._recvTransport.id });

                await this._recvTransport.restartIce({ iceParameters });
            }

            /*store.dispatch(requestActions.notify(
                {
                    text: 'ICE restarted'
                }));*/
        }
        catch (error) {
            console.log('restartIce() | failed:%o', error);

            events.emit('room:error', `ICE restart failed: ${error}`);
        }

        //store.dispatch(
        //    stateActions.setRestartIceInProgress(false));
    }

    async setConsumerPreferredLayers(consumerId: any, spatialLayer: any, temporalLayer: any) {
        console.log(
            'setConsumerPreferredLayers() [consumerId:%s, spatialLayer:%s, temporalLayer:%s]',
            consumerId, spatialLayer, temporalLayer);

        try {
            await this._protoo.request(
                'setConsumerPreferredLayers', { consumerId, spatialLayer, temporalLayer });

            //store.dispatch(stateActions.setConsumerPreferredLayers(
            //    consumerId, spatialLayer, temporalLayer));
        }
        catch (error) {
            console.log('setConsumerPreferredLayers() | failed:%o', error);

            events.emit('room:error', `Error setting Consumer preferred layers: ${error}`);
        }
    }

    async setConsumerPriority(consumerId: any, priority: any) {
        console.log(
            'setConsumerPriority() [consumerId:%s, priority:%d]',
            consumerId, priority);

        try {
            await this._protoo.request('setConsumerPriority', { consumerId, priority });

            //store.dispatch(stateActions.setConsumerPriority(consumerId, priority));
        }
        catch (error) {
            console.log('setConsumerPriority() | failed:%o', error);

            events.emit('room:error', `Error setting Consumer priority: ${error}`);
        }
    }

    async requestConsumerKeyFrame(consumerId: any) {
        console.log('requestConsumerKeyFrame() [consumerId:%s]', consumerId);

        try {
            await this._protoo.request('requestConsumerKeyFrame', { consumerId });

            /*store.dispatch(requestActions.notify(
                {
                    text: 'Keyframe requested for video consumer'
                }));*/
        }
        catch (error) {
            console.log('requestConsumerKeyFrame() | failed:%o', error);

            events.emit('room:error', `Error requesting key frame for Consumer: ${error}`);
        }
    }

    async changeDisplayName(displayName: any) {
        console.log('changeDisplayName() [displayName:"%s"]', displayName);

        try {
            await this._protoo.request('changeDisplayName', { displayName });

            this._displayName = displayName;

            //store.dispatch(
            //    stateActions.setDisplayName(displayName));

            /*store.dispatch(requestActions.notify(
                {
                    text: 'Display name changed'
                }));*/
        }
        catch (error) {
            console.log('changeDisplayName() | failed: %o', error);

            events.emit('room:error', `Could not change display name: ${error}`);

            // We need to refresh the component for it to render the previous
            // displayName again.
            //store.dispatch(
            //    stateActions.setDisplayName());
        }
    }

    async getSendTransportRemoteStats() {
        console.log('getSendTransportRemoteStats()');

        if (!this._sendTransport)
            return;

        return this._protoo.request(
            'getTransportStats', { transportId: this._sendTransport.id });
    }

    async getRecvTransportRemoteStats() {
        console.log('getRecvTransportRemoteStats()');

        if (!this._recvTransport)
            return;

        return this._protoo.request(
            'getTransportStats', { transportId: this._recvTransport.id });
    }

    async getAudioRemoteStats() {
        console.log('getAudioRemoteStats()');

        if (!this._micProducer)
            return;

        return this._protoo.request(
            'getProducerStats', { producerId: this._micProducer.id });
    }


    async getConsumerRemoteStats(consumerId: any) {
        console.log('getConsumerRemoteStats()');

        const consumer = this._consumers.get(consumerId);

        if (!consumer)
            return;

        return this._protoo.request('getConsumerStats', { consumerId });
    }

    /*async getDataConsumerRemoteStats(dataConsumerId: any) {
        console.log('getDataConsumerRemoteStats()');

        const dataConsumer = this._dataConsumers.get(dataConsumerId);

        if (!dataConsumer)
            return;

        return this._protoo.request('getDataConsumerStats', { dataConsumerId });
    }*/

    async getSendTransportLocalStats() {
        console.log('getSendTransportLocalStats()');

        if (!this._sendTransport)
            return;

        return this._sendTransport.getStats();
    }

    async getRecvTransportLocalStats() {
        console.log('getRecvTransportLocalStats()');

        if (!this._recvTransport)
            return;

        return this._recvTransport.getStats();
    }

    async getAudioLocalStats() {
        console.log('getAudioLocalStats()');

        if (!this._micProducer)
            return;

        return this._micProducer.getStats();
    }

    async getConsumerLocalStats(consumerId: any) {
        const consumer = this._consumers.get(consumerId);

        if (!consumer)
            return;

        return consumer.getStats();
    }

    async _joinRoom() {
        console.log('_joinRoom()');

        try {
            this._mediasoupDevice = new mediasoupClient.Device(
                {
                    handlerName: this._handlerName
                });

            const routerRtpCapabilities =
                await this._protoo.request('getRouterRtpCapabilities');

            await this._mediasoupDevice.load({ routerRtpCapabilities });

            // NOTE: Stuff to play remote audios due to browsers' new autoplay policy.
            //
            // Just get access to the mic and DO NOT close the mic track for a while.
            // Super hack!
            {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const audioTrack = stream.getAudioTracks()[0];

                audioTrack.enabled = false;

                setTimeout(() => audioTrack.stop(), 120000);
            }
            // Create mediasoup Transport for sending (unless we don't want to produce).
            {
                const transportInfo = await this._protoo.request(
                    'createWebRtcTransport',
                    {
                        forceTcp: this._forceTcp,
                        producing: true,
                        consuming: false,
                        sctpCapabilities: /*this._useDataChannel
                            ? this._mediasoupDevice.sctpCapabilities
                            : */undefined
                    });

                const {
                    id,
                    iceParameters,
                    iceCandidates,
                    dtlsParameters,
                    sctpParameters
                } = transportInfo;

                this._sendTransport = this._mediasoupDevice.createSendTransport(
                    {
                        id,
                        iceParameters,
                        iceCandidates,
                        dtlsParameters:
                        {
                            ...dtlsParameters,
                            // Remote DTLS role. We know it's always 'auto' by default so, if
                            // we want, we can force local WebRTC transport to be 'client' by
                            // indicating 'server' here and vice-versa.
                            role: 'auto'
                        },
                        sctpParameters,
                        iceServers: [],
                        proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
                        additionalSettings:
							{ encodedInsertableStreams: this._e2eKey && e2e.isSupported() }
                    });

                this._sendTransport.on(
                    'connect', ({ dtlsParameters }: any, callback: any, errback: any) => // eslint-disable-line no-shadow
                {
                    this._protoo.request(
                        'connectWebRtcTransport',
                        {
                            transportId: this._sendTransport.id,
                            dtlsParameters
                        })
                        .then(callback)
                        .catch(errback);
                });

                this._sendTransport.on(
                    'produce', async ({ kind, rtpParameters, appData }: any, callback: any, errback: any) => {
                        try {
                            // eslint-disable-next-line no-shadow
                            const { id } = await this._protoo.request(
                                'produce',
                                {
                                    transportId: this._sendTransport.id,
                                    kind,
                                    rtpParameters,
                                    appData
                                });

                            callback({ id });
                        }
                        catch (error) {
                            errback(error);
                        }
                    });

                this._sendTransport.on('producedata', async (
                    {
                        sctpStreamParameters,
                        label,
                        protocol,
                        appData
                    }: any,
                    callback: any,
                    errback: any
                ) => {
                    console.log(
                        '"producedata" event: [sctpStreamParameters:%o, appData:%o]',
                        sctpStreamParameters, appData);

                    try {
                        // eslint-disable-next-line no-shadow
                        const { id } = await this._protoo.request(
                            'produceData',
                            {
                                transportId: this._sendTransport.id,
                                sctpStreamParameters,
                                label,
                                protocol,
                                appData
                            });

                        callback({ id });
                    }
                    catch (error) {
                        errback(error);
                    }
                });
            }

            // Create mediasoup Transport for receiving (unless we don't want to consume).
            {
                const transportInfo = await this._protoo.request(
                    'createWebRtcTransport',
                    {
                        forceTcp: this._forceTcp,
                        producing: false,
                        consuming: true,
                        sctpCapabilities: /*this._useDataChannel
                            ? this._mediasoupDevice.sctpCapabilities
                            : */undefined
                    });

                const {
                    id,
                    iceParameters,
                    iceCandidates,
                    dtlsParameters,
                    sctpParameters
                } = transportInfo;

                this._recvTransport = this._mediasoupDevice.createRecvTransport(
                    {
                        id,
                        iceParameters,
                        iceCandidates,
                        dtlsParameters:
                        {
                            ...dtlsParameters,
                            // Remote DTLS role. We know it's always 'auto' by default so, if
                            // we want, we can force local WebRTC transport to be 'client' by
                            // indicating 'server' here and vice-versa.
                            role: 'auto'
                        },
                        sctpParameters,
                        iceServers: [],
                        additionalSettings:
							{ encodedInsertableStreams: this._e2eKey && e2e.isSupported() }
                    });

                this._recvTransport.on(
                    'connect', ({ dtlsParameters }: any, callback: any, errback: any) => // eslint-disable-line no-shadow
                {
                    this._protoo.request(
                        'connectWebRtcTransport',
                        {
                            transportId: this._recvTransport.id,
                            dtlsParameters
                        })
                        .then(callback)
                        .catch(errback);
                });
            }

            // Join now into the room.
            // NOTE: Don't send our RTP capabilities if we don't want to consume.
            const { peers } = await this._protoo.request(
                'join',
                {
                    displayName: this._displayName,
                    device: this._device,
                    rtpCapabilities: true
                        ? this._mediasoupDevice.rtpCapabilities
                        : undefined,
                    sctpCapabilities: /*this._useDataChannel
                        ? this._mediasoupDevice.sctpCapabilities
                        : */undefined
                });

            this.connected = true;
            events.emit('voice_chat:connected', true);
            events.emit('room:state', 'connected');

            //store.dispatch(
            //    stateActions.setRoomState('connected'));

            // Clean all the existing notifcations.
            //store.dispatch(
            //    stateActions.removeAllNotifications());


            for (const peer of peers) {
                console.log('adding peers', peer);
                this._add_peer(peer);
                //store.dispatch(
                //    stateActions.addPeer(
                //        { ...peer, consumers: [], dataConsumers: [] }));
            }

            // Enable mic/webcam.
            //{
            // Set our media capabilities.
            //store.dispatch(stateActions.setMediaCapabilities(
            //    {
            //        canSendMic: this._mediasoupDevice.canProduce('audio'),
            //        canSendWebcam: this._mediasoupDevice.canProduce('video')
            //    }));

            this.enableMic();
            //}
        }
        catch (error) {
            console.log('_joinRoom() failed:%o', error);

            events.emit('room:error', `Could not join the room: ${error}`);

            this.close();
        }
    }

    async _pauseConsumer(consumer: any) {
        if (consumer.paused)
            return;

        try {
            await this._protoo.request('pauseConsumer', { consumerId: consumer.id });

            consumer.pause();

            //store.dispatch(
            //    stateActions.setConsumerPaused(consumer.id, 'local'));
        }
        catch (error) {
            console.log('_pauseConsumer() | failed:%o', error);

            events.emit('room:error', `Error pausing Consumer: ${error}`);
        }
    }

    async _resumeConsumer(consumer: any) {
        if (!consumer.paused)
            return;

        try {
            await this._protoo.request('resumeConsumer', { consumerId: consumer.id });

            consumer.resume();

            //store.dispatch(
            //    stateActions.setConsumerResumed(consumer.id, 'local'));
        }
        catch (error) {
            console.log('_resumeConsumer() | failed:%o', error);

            events.emit('room:error', `Error resuming Consumer: ${error}`);
        }
    }

    async set_input_device(device: any) {
        console.log('set_input_device', device);
        await this.disableMic();
        await this.enableMic(device);
    }

    set_mic_volume(value: number) {
        console.log('set_mic_volume: ' + value);
        if (this._gain_node) {
            this._gain_node.gain.value = value;
        }
    }
}
