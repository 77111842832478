import './claimr_page.css'

export function ClaimrPage() {
    return (
        <div className='cp-root'>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '40px',
            }}>
                <div style={{
                    width: '90%',
                    height: '500px',
                }}>
                    <iframe id="claimr-widget-campaigns" scrolling="no" frameBorder="0" style={{
                        width: '100%',
                        height: '100%',
                    }}
                        title="Claimr Campaigns" src={`https://localhost:3012/widgets/campaigns/hmstrs`} />
                </div>
                <div style={{
                    width: '90%',
                    height: '400px',
                }}>
                    <iframe id="claimr-widget-rewards" scrolling="no" frameBorder="0" style={{
                        width: '100%',
                        height: '100%',
                    }}
                        title="Claimr Rewards" src={`https://localhost:3012/widgets/rewards`} />
                </div>
            </div>
        </div>
    )
}