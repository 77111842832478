import { events_emitter } from "./events_emitter";

const context = {}

export const emit = (event: string, ...data: any[]) => {
    events_emitter.emit(context, event, ...data);
};

export const on = (event: string, handler: (...data: any) => any) => {
    events_emitter.on(context, event, handler);
};

export const off = (event: string, handler: (...data: any) => void) => {
    events_emitter.off(context, event, handler);
};