import { useEffect, useRef, useState } from "react";
import { Connection } from "../../core/connection";
import settings from "../../core/settings";
import * as events from '../../core/events'
import { useSessionStore } from "../../stores/session_store";
import './text_chat_overlay.css'

interface Props {
    room_id: string;
    account_id: string;
}

let emojis: string[] = ['😀', '😁', '😊', '🤬', '🤯', '☠️', '🤗', '😎', '😵', '😳', '😴', '🥳', '🙃', '😶', '😴', '😭'];
let connection: Connection = new Connection();

export function TextChatOverlay(props: Props) {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const messagesRef = useRef<HTMLDivElement>(null);
    const [opened, set_opened] = useState(false);
    const [message, set_message] = useState('');
    const [chat_messages, set_chat_messages] = useState<any[]>([]);
    const [client_id, set_client_id] = useState('');
    const [unread_count, set_unread_count] = useState<number>(0);
    const small_avatar = useSessionStore(state => state.small_avatar);
    const username = useSessionStore(state => state.username);

    useEffect(() => {
        events.on('net:chat:clients:created', on_connect_event);
        events.on('net:chat:peer_message', on_peer_message_event);

        connection.prefix = 'chat:';
        connection.init(settings.text_chat_server_url);

        return () => {
            connection.destroy();
            events.off('net:chat:clients:created', on_connect_event);
            events.off('net:chat:peer_message', on_peer_message_event);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
        set_unread_count(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opened]);

    useEffect(() => {
        if (chat_messages.length > 0) {
            if (opened) {
                set_unread_count(0);
            } else {
                set_unread_count(unread_count + 1);
            }    
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chat_messages]);

    const on_connect_event = (data: any) => {
        //console.log('on_connect_event', data);

        set_client_id(data.client_id);

        connection.send({
            command: 'client:info',
            data: {
                room_id: props.room_id,
                account_id: props.account_id,
                username,
                avatar: small_avatar,
            },
            sender_id: data.client_id,
            room_id: props.room_id,
            context: 'system',
        });
    }

    const on_peer_message_event = (data: any) => {
        //console.log('on_peer_message_event', data);
        set_chat_messages(chat_messages => [...chat_messages, data]);
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }

    const add_emojii = (emoji: string) => {
        if (inputRef.current) {
            const selection_start = inputRef.current.selectionStart ? inputRef.current.selectionStart : 0;
            const selection_end = inputRef.current.selectionEnd ? inputRef.current.selectionEnd : 0;
            let new_message = [message.slice(0, selection_start), emoji, message.slice(selection_end)].join('');
            set_message(new_message);
            inputRef.current.value = new_message;
            inputRef.current.focus();
            inputRef.current.selectionEnd = selection_start + emoji.length;
        }
    }

    const on_send = () => {
        connection.send({
            command: 'peer_message',
            data: {
                account_id: props.account_id,
                username,
                message,
            },
            sender_id: client_id,
            room_id: props.room_id,
            context: 'local',
        });
        set_message('');
        inputRef.current?.focus();
    }

    return (
        <div className={"tco-root" + (opened ? " tco-opened" : "")}>
            <div className="tco-title">
                Text Chat
            </div>
            {!opened && unread_count > 0 &&
                <div className='tco-unread'>
                    {unread_count} messages
                </div>
            }
            {opened ?
                <button className="tco-button" onClick={() => set_opened(false)}>
                    CLOSE
                </button>
                :
                <button className="tco-button" onClick={() => set_opened(true)}>
                    OPEN
                </button>
            }
            <div className="tco-container">
                {opened &&
                    <>
                        Messages:
                        <div className="tco-messages-block" ref={messagesRef}>
                            {
                                chat_messages.map((chat_message, index) => {
                                    return (
                                        <div className={chat_message.account_id === props.account_id ? "tco-own-message" : "tco-message"} key={index}>
                                            <div className="tco-message-sender">
                                                {chat_message.username + ':'}
                                            </div>
                                            <div className="tco-message-text">
                                                {chat_message.message}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="tco-message-block">
                            <div className="">
                                <textarea className='tco-message-input' ref={inputRef} autoFocus id="chat-message" value={message} onChange={event => set_message(event.target.value)} />
                                <button className="tco-send-button" onClick={on_send} disabled={message.trim() === ''}>
                                    SEND
                                </button>
                            </div>
                            {
                                emojis.map((emoji, index) => {
                                    return (
                                        <button className="tco-emoji" key={index} tabIndex={-1} onClick={() => add_emojii(emoji)}>
                                            {emoji}
                                        </button>
                                    );
                                })
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
}