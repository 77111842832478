import React, { RefObject } from "react";
import { useSessionStore } from "../stores/session_store";
import { Navigate } from "react-router-dom";

import './game_iframe.css'

interface Props {
    game_title: string;
    game_url: string;
    company_title: string;
}

export default class GameIFrame extends React.Component<any, any> {
    iframeRef: RefObject<HTMLIFrameElement>;

    constructor(props: Props) {
        super(props);

        this.iframeRef = React.createRef();

        this.state = {
            tournament_id: useSessionStore.getState().tournament_id,
            completed: false,
        }
    }

    _on_iframe_event = this._on_iframe_message.bind(this);

    componentDidMount() {
        window.addEventListener("message", this._on_iframe_event);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this._on_iframe_event);
    }

    _on_iframe_message(event: MessageEvent) {
        if (event.data.from === "game") {
            const message_type = event.data.data[0];

            if (message_type === "game:init") {
                this._send_app_init();
            } else if (message_type === "player:state") {
                localStorage.setItem('tmp-aid', useSessionStore.getState().account_id);
                this.setState({
                    completed: true,
                })
                //const url = '/t/' + useSessionStore.getState().tournament_id;
                //console.log(url);
            }
        }
    }

    _send_app_init() {
        let data = {
            username: useSessionStore.getState().username,
            account_id: useSessionStore.getState().account_id,
            title: this.props.company_title,
            avatars: useSessionStore.getState().avatars,
            small_avatar: useSessionStore.getState().small_avatar,
            room_id: useSessionStore.getState().room_id,
            invitation_key: useSessionStore.getState().invitation_key,
            subscription_id: useSessionStore.getState().subscription_id,
        };
        this.postMessage('app:init', data);
    }

    postMessage = (...args: any[]) => {
        const contentWindow = this.iframeRef.current?.contentWindow;
        if (contentWindow) {
            contentWindow.postMessage(
                {
                    sender: "front",
                    data: args,
                },
                "*"
            );
        }
    };

    render() {
        const { game_url, game_title } = this.props;
        return (
            <div className="root">
                {this.state.completed && <Navigate to={'/t/' + this.state.tournament_id} replace={true} />}
                <iframe
                    id="game"
                    ref={this.iframeRef}
                    src={game_url}
                    frameBorder="0"
                    allowFullScreen
                    title={game_title}
                />
            </div>
        );
    }
}