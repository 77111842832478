import { useEffect, useState } from 'react';
import { post_json } from '../../../core/fetch';
import settings from '../../../core/settings';
import * as events from '../../../core/events'

import './dev_create_tournament_page.css'
import { useAccountStore } from '../../../stores/account_store';

const table_types = [
    { key: '5_seats', value: 'Five Seats', max_seats: 5 },
    { key: '6_seats', value: 'Six Seats', max_seats: 6 },
    { key: '8_seats', value: 'Eight Seats', max_seats: 8 },
]

const blind_types = [
    { key: '1_2', value: 'small 1 / big 2', small_blind: 1, big_blind: 2 },
    { key: '5_10', value: 'small 5 / big 10', small_blind: 5, big_blind: 10 },
    { key: '25_50', value: 'small 25 / big 50', small_blind: 25, big_blind: 50 },
]

export function DevCreateTournamentPage() {
    const [id, set_id] = useState('');
    const [next_id, set_next_id] = useState('');
    const [name, set_name] = useState('Test');
    const [datetime, set_datetime] = useState('2022-05-22T00:00');
    const [players_count, set_players_count] = useState<number>(60);
    const [buy_in, set_buy_in] = useState<number>(1000);
    const [table_type_index, set_table_type_index] = useState<number>(1);
    const [blind_type_index, set_blind_type_index] = useState<number>(1);
    const [blind_inc_time, set_blind_inc_time] = useState<number>(0);
    const [ante, set_ante] = useState<number>(0);
    const [is_public, set_is_public] = useState<boolean>(true);
    const [time_limit, set_time_limit] = useState<number>(0);
    const [locale_time, set_locale_time] = useState('');

    const access_token = useAccountStore(state => state.access_token);

    useEffect(() => {
        let now = new Date(Date.now() + 1000 * 60);
        const date = now.getFullYear() + '-' +
            (now.getMonth() + 1).toString().padStart(2, "0") + '-' +
            now.getDate().toString().padStart(2, "0");
        const time = now.getHours().toString().padStart(2, "0") + ":" +
            now.getMinutes().toString().padStart(2, "0");
        set_datetime(`${date}T${time}`);

        onTick();
        const interval_id = setInterval(onTick, 1000);

        return () => {
            clearInterval(interval_id);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onTick = () => {
        const date = new Date();
        set_locale_time(date.toLocaleString());
    }

    const on_create = () => {
        let date = new Date(datetime);
        /*console.log('lcl: ' + date.toString());
        console.log('iso: ' + date.toISOString());
        console.log('utc: ' + date.toUTCString());*/
        console.log(is_public);
        post_json(settings.tournaments_server_url + '/create_tournament', {
            tournament_id: id !== '' ? id : null,
            next_id,
            name,
            datetime: date.toISOString(),
            is_public,
            max_players: players_count,
            buy_in,
            small_blind: blind_types[blind_type_index].small_blind,
            big_blind: blind_types[blind_type_index].big_blind,
            max_seats: table_types[table_type_index].max_seats,
            blind_inc_time,
            ante,
            time_limit,
        }, access_token).then(data => {
            console.log(data);
            events.emit('tournaments:create:close');
        });
    }

    const on_cancel = () => {
        events.emit('tournaments:create:close');
    }

    return (
        <div className='ctp-root'>
            <div className='ctp-dialog'>
                <div className='ctp-title'>
                    {"Id (optional)"}
                </div>
                <input className='ctp-input' id='tournament-id' type="text" value={id} onChange={(event) => set_id(event.target.value)} />
                <div className='ctp-title'>
                    {"Next Id (optional)"}
                </div>
                <input className='ctp-input' id='next-tournament-id' type="text" value={next_id} onChange={(event) => set_next_id(event.target.value)} />

                <div className='ctp-title'>
                    Is Public
                </div>
                <input className='ctp-input' id='tournament-public' type="checkbox" checked={is_public} onChange={() => set_is_public(!is_public)} />


                <div className='ctp-title'>
                    Name
                </div>
                <input className='ctp-input' id='tournament-name' type="text" value={name} onChange={(event) => set_name(event.target.value)} />

                <div className="ctp-time">
                    {locale_time}
                </div>
                <div>
                    <input className="ctp-datetime" id="datetime" type="datetime-local" value={datetime} onChange={(event) => set_datetime(event.target.value)} />
                </div>

                <div className='ctp-title'>
                    players count
                    <input className='ctp-input' id='tournament-player-count' type="number" value={players_count} onChange={(event) => set_players_count(parseInt(event.target.value))} />
                </div>
                <div className='ctp-title'>
                    table type
                    <select className='ctp-select' value={table_types[table_type_index].key} onChange={(event) => set_table_type_index(event.target.selectedIndex)}>
                        {
                            table_types.map((table_type, index) => {
                                return (
                                    <option value={table_type.key} key={index}>{table_type.value}</option>
                                );
                            })
                        }
                    </select>
                </div>
                <div className='ctp-title'>
                    buy-in
                    <input className='ctp-input' id='tournament-buy-in' type="number" value={buy_in} onChange={(event) => set_buy_in(parseInt(event.target.value))} />
                </div>
                <div className='ctp-title'>
                    small/big blinds type
                    <select className='ctp-select' value={blind_types[blind_type_index].key} onChange={(event) => set_blind_type_index(event.target.selectedIndex)}>
                        {
                            blind_types.map((blind_type, index) => {
                                return (
                                    <option value={blind_type.key} key={index}>{blind_type.value}</option>
                                );
                            })
                        }
                    </select>
                </div>
                <div className='ctp-title'>
                    blind increase interval
                    <input className='ctp-input' id='tournament-blind-inc-time' type="number" value={blind_inc_time} onChange={(event) => set_blind_inc_time(parseInt(event.target.value))} />
                </div>
                <div className='ctp-title'>
                    ante
                    <input className='ctp-input' id='tournament-ante' type="number" value={ante} onChange={(event) => set_ante(parseInt(event.target.value))} />
                </div>
                <div className='ctp-title'>
                    time limit
                    <input className='ctp-input' id='tournament-time-limit' type="number" value={time_limit} onChange={(event) => set_time_limit(parseInt(event.target.value))} />
                </div>

                <button className="ctp-button" onClick={on_create}>
                    create
                </button>
                <button className="ctp-button" onClick={on_cancel}>
                    cancel
                </button>
            </div>
        </div >
    );
}