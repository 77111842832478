import settings from '../../core/settings'
import './klink_page.css'

export function KlinkPage() {
    return (
        <div className='kp-root'>
            <iframe id="claimr-widget-organizations" scrolling="no" frameBorder="0" style={{
                width: '100%',
                height: '100%',
            }}
                title="KlinkFinance" src={`${settings.claimr_url}/widgets/organizations/klinkfinance`} />
        </div>
    )
}