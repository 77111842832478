import { useEffect, useState } from "react";
import * as events from '../core/events'

import './voice_chat_settings.css'

export function VoiceChatSettings() {
    const [audioinput_devices, set_audioinput_devices] = useState<any[]>([]);
    const [audiooutput_devices, set_audiooutput_devices] = useState<any[]>([]);

    useEffect(() => {
        fetch_connected_devices();

        navigator.mediaDevices.addEventListener('devicechange', on_device_change);
        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', on_device_change);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const on_device_change = (event: any) => {
        fetch_connected_devices();
    }

    const fetch_connected_devices = () => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const filtered = devices.filter(device => device.kind === 'audioinput');
                set_audioinput_devices(filtered);
                //console.log('audioinput', filtered);
            });
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const filtered = devices.filter(device => device.kind === 'audiooutput');
                set_audiooutput_devices(filtered);
                //console.log('audiooutput', filtered);
            });
    }

    const on_close = () => {
        events.emit('voice_chat:settings:close');
    }

    const on_output_change = (event: any) => {
        //console.log('on_output_change', event);
        events.emit('voice_chat:output:set', audiooutput_devices[event.target.selectedIndex]);
    }

    const on_input_change = (event: any) => {
        //console.log('on_input_change', event);
        events.emit('voice_chat:input:set', audioinput_devices[event.target.selectedIndex]);
    }

    return (
        <div className="vcs-root">
            <div className="vcs-dialog">
                <div className="vcs-title">
                    VOICE CHAT SETTINGS
                </div>
                <div className="vcs-text">
                    AUDIO:
                </div>
                <div>
                    <select className="vcs-select" onChange={on_output_change}>
                        {audiooutput_devices.map((value: any, index: number) => {
                            return <option key={index} value={value.deviceId}>
                                {value.label}
                            </option>
                        })}
                    </select>
                </div>
                <div className="vcs-text">
                    MIC:
                </div>
                <div>
                    <select className="vcs-select" onChange={on_input_change}>
                        {audioinput_devices.map((value: any, index: number) => {
                            return <option key={index} value={value.deviceId}>
                                {value.label}
                            </option>
                        })}
                    </select>
                </div>
                <div>
                    <button className='vcs-close-button' onClick={on_close}>
                        CLOSE
                    </button>
                </div>
            </div>
        </div>
    );
}