import { useState } from 'react';
import { post_json } from '../../core/fetch';
import { sha256 } from '../../core/hash';
import settings from '../../core/settings';
import { validate_email } from '../../core/utils';
import './auth_reset_password_component.css'

interface Props {
    recovery_key: string,
    on_close: any,
}

export function AuthResetPasswordComponent(props: Props) {
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');
    const [password2, set_password2] = useState('');

    const on_reset = async () => {
        post_json(settings.accounts_server_url + '/api/reset', {
            address: email,
            password: await sha256(password),
            recovery_key: props.recovery_key,
        }).then(() => {
            props.on_close(true);
        });
    }

    return (
        <div>
            <div className='asc-text'>
                ENTER YOUR EMAIL
            </div>
            <div>
                <input className={validate_email(email) ? 'asc-input' : 'asc-input-error'} id="email" type="text" value={email} onChange={event => set_email(event.target.value.toLowerCase())} />
            </div>
            <div className='asc-text'>
                ENTER A PASSWORD
            </div>
            <div>
                <input className='asc-input' id="password" type="password" value={password} onChange={event => set_password(event.target.value)} />
            </div>
            <div className='asc-text'>
                CONFIRM A PASSWORD
            </div>
            <div>
                <input className={password === password2 ? 'asc-input' : 'asc-input-error'} id="password2" type="password" value={password2} onChange={event => set_password2(event.target.value)} />
            </div>
            <div>
                <button className='asc-button' onClick={on_reset} disabled={password === '' || password !== password2}>
                    RESET PASSWORD
                </button>
            </div>
        </div>
    );
}