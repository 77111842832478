import { useEffect, useState } from "react";
import { post_json } from "../../../core/fetch";
import settings from "../../../core/settings";
import { DevLoginPage } from "../dev_login_page";
import * as events from '../../../core/events'
import { DevCreateTournamentPage } from "./dev_create_tournament_page";
import storage from "../../../core/storage";
import { SubscriptionStateComponent } from "./subsctiption_state_component";

import './dev_tournaments_page.css'
import { useAccountStore } from "../../../stores/account_store";

export function DevTournamentsPage() {
    const [tournaments, set_tournaments] = useState<any[]>([]);
    const [subscriptions, set_subscriptions] = useState<any[]>([]);
    const [stats, set_stats] = useState<any[]>([]);
    //const [accounts, set_accounts] = useState<any[]>([]);
    const [create_opened, set_create_opened] = useState<boolean>(false);

    const access_token = useAccountStore(state => state.access_token);
    const account = useAccountStore(state => state.account);
    const set_access_token = useAccountStore(state => state.set_access_token);
    const fetch_account = useAccountStore(state => state.fetch_account);

    useEffect(() => {
        console.log('use_effect');
        events.on('tournaments:create:close', close_create);

        if (access_token !== '') {
            set_access_token(access_token);
            fetch_account();
        }

        return () => {
            events.off('tournaments:create:close', close_create);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (account) {
            fetch_tournaments();
            fetch_subscriptions();
            //fetch_accounts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    useEffect(() => {
        if (subscriptions) {
            let subscription_stats: any = [];
            for (const subscription of subscriptions) {
                if (subscription.current_state.S === 'created') {
                    let sub_stat = subscription_stats.find((entry: any) => {
                        return entry.tournament_id === subscription.tournament_id.S;
                    });
                    if (sub_stat) {
                        ++sub_stat.count;
                    } else {
                        sub_stat = {
                            tournament_id: subscription.tournament_id.S,
                            count: 1,
                        }
                        subscription_stats.push(sub_stat);
                    }
                }
            }
            set_stats(subscription_stats);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptions])

    const on_refresh = () => {
        if (account) {
            fetch_tournaments();
            fetch_subscriptions();
        }
    }

    const fetch_tournaments = () => {
        post_json(settings.tournaments_server_url + '/tournaments', {
        }, access_token).then(data => {
            console.log(data);
            if (data.status === 200) {
                if (data.data) {
                    set_tournaments(data.data);
                }
            }
        });
    }

    const fetch_subscriptions = () => {
        post_json(settings.tournaments_server_url + '/subscriptions', {
        }, access_token).then(data => {
            console.log(data);
            if (data.status === 200) {
                if (data.data) {
                    set_subscriptions(data.data);
                }
            }
        });
    }

    /*const fetch_accounts = () => {
        post_json(settings.accounts_server_url + '/accounts', {})
            .then(data => {
                console.log(data);
                if (data.status === 200) {
                    if (data.data) {
                        set_accounts(data.data);
                    }
                }
            });
    }*/

    const open_create = () => {
        set_create_opened(true);
    }

    const close_create = () => {
        set_create_opened(false);
        fetch_tournaments();
    }

    const on_results = (tournament_id: string) => {
        localStorage.setItem('tmp-aid', account.account_id);
        const win: any = window.open('/dev/t/' + tournament_id, '_blank');
        win.focus();
    }

    const on_delete = (tournament_id: string) => {
        post_json(settings.tournaments_server_url + '/delete_tournament', {
            tournament_id
        }, access_token).then(data => {
            console.log(data);
            fetch_tournaments();
        });
    }

    const on_visit = (url: string) => {
        const win: any = window.open('/dev' + url, '_blank');
        win.focus();
    }

    const on_subscribe = (tournament_id: string, account_id: string) => {
        post_json(settings.tournaments_server_url + '/create_subscription', {
            account_id,
            tournament_id
        }, storage.jwt).then(data => {
            console.log(data);
            fetch_subscriptions();
        });
    }

    const on_start_tournament = (tournament_id: string) => {
        post_json(settings.tournaments_server_url + '/start_tournament', {
            tournament_id
        }, access_token).then(data => {
            console.log(data);
            fetch_tournaments();
        });
    }

    const on_close_tournament = (tournament_id: string) => {
        post_json(settings.tournaments_server_url + '/close_tournament', {
            tournament_id
        }, access_token).then(data => {
            console.log(data);
            fetch_tournaments();
        });
    }

    const on_unsubscribe = (subscription_id: string) => {
        post_json(settings.tournaments_server_url + '/delete_subscription', {
            subscription_id
        }, access_token).then(data => {
            console.log(data);
            fetch_subscriptions();
        });
    }

    return (
        <div className="tp-root">
            {!account ?
                <div>
                    <DevLoginPage />
                </div>
                :
                <div>
                    <div className="tp-address">
                        ID: {account.account_id}<br />
                        ADDRESS: {account.address}
                    </div>

                    <div className="tp-title">
                        TOURNAMENTS
                    </div>

                    <div>
                        <button className="tp-button" onClick={open_create}>
                            create new tournament
                        </button>
                    </div>
                    <div>
                        <button className="tp-button" onClick={on_refresh}>
                            refresh
                        </button>
                    </div>

                    {
                        tournaments.map((tournament, index) => {
                            return (
                                <div className="tp-tournament" key={index}>
                                    id: {tournament.tournament_id.S} <br />
                                    next_id: {tournament.next_id ? tournament.next_id.S : ''} <br />
                                    name: {tournament.name.S} <br />
                                    state: {tournament.current_state.S} <br />
                                    clients: {tournament.clients.N} <br />
                                    date: {tournament.datetime.S} <br />
                                    max_players: {tournament.max_players.N} <br />
                                    max_seats: {tournament.max_seats.N} <br />
                                    buy_in: {tournament.buy_in.N} <br />
                                    small_blind: {tournament.small_blind.N} <br />
                                    big_blind: {tournament.big_blind.N} <br />
                                    blind_inc_time: {tournament.blind_inc_time ? tournament.blind_inc_time.N : 0} <br />
                                    time_limit: {tournament.time_limit ? tournament.time_limit.N : 0} <br />
                                    {account.address !== '' && <button className="tp-button" onClick={() => on_subscribe(tournament.tournament_id.S, account.account_id)}>
                                        subscribe
                                    </button>}
                                    {tournament.current_state.S === 'created' && <button className="tp-button" onClick={() => on_start_tournament(tournament.tournament_id.S)}>
                                        start
                                    </button>}
                                    {tournament.current_state.S === 'booked' && <button className="tp-button" onClick={() => on_close_tournament(tournament.tournament_id.S)}>
                                        close
                                    </button>}
                                    <button className="tp-button" onClick={() => on_results(tournament.tournament_id.S)}>
                                        results
                                    </button>
                                    <button className="tp-button" onClick={() => on_delete(tournament.tournament_id.S)}>
                                        delete
                                    </button>
                                </div>
                            );
                        })
                    }

                    <div className="tp-title">
                        SUBSCRIPTIONS
                    </div>
                    {
                        stats.map((entry, index) => {
                            return (
                                <div className="tp-own-subscription" key={index}>
                                    tournament_id: {entry.tournament_id} <br />
                                    count: {entry.count} <br />
                                </div>
                            );
                        })
                    }
                    {
                        subscriptions.map((subscription, index) => {
                            return (
                                <div className={subscription.account_id.S === account.account_id ?
                                    "tp-own-subscription" : "tp-subscription"} key={index}>
                                    subscription_id: {subscription.subscription_id.S} <br />
                                    tournament_id: {subscription.tournament_id.S} <br />
                                    account_id: {subscription.account_id.S} <br />
                                    current_state: <SubscriptionStateComponent state={subscription.current_state.S} /> <br />
                                    <button className="tp-button" onClick={() => on_visit(subscription.invitation_link.S)}>
                                        visit
                                    </button>
                                    <button className="tp-button" onClick={() => on_unsubscribe(subscription.subscription_id.S)}>
                                        delete
                                    </button>
                                </div>
                            );
                        })
                    }
                    {/*
                        accounts.map((account, index) => {
                            return (
                                <div className="ap-account" key={index}>
                                    {account.name.S} / {account.account_id.S} / {account.address ? account.address.S : ''}
                                    {
                                        subscriptions.findIndex((entry) => {
                                            return entry.account_id.S === account.account_id.S && entry.tournament_id.S === 'doge';
                                        }) < 0 &&
                                        <button className="ap-account-button" onClick={() => on_subscribe('doge', account.account_id.S)}>
                                            subscribe
                                        </button>
                                    }
                                </div>
                            );
                        })
                    */}
                </div>
            }
            {create_opened && <DevCreateTournamentPage />}
        </div>
    );
}