import { useEffect, useState } from "react";
import './tournament_timer_component.css'

interface Props {
    datetime: string;
}

export function TournamentTimerComponent(props: Props) {
    const [deadline, set_deadline] = useState('');
    const [started, set_started] = useState(false);

    useEffect(() => {
        const delta = get_delta();
        if (delta < 0) {
            set_started(true);
            return;
        }

        const tick_interval_id = setInterval(on_tick, 1000);
        on_tick();

        return () => {
            clearInterval(tick_interval_id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const get_delta = () => {
        const now = Date.now();
        const dl = new Date(props.datetime).getTime();
        return dl - now;
    }

    const on_tick = () => {
        const delta = get_delta();
        if (delta >= 0) {
            const delta_in_seconds = Math.floor(delta / 1000);
            const seconds = delta_in_seconds % 60;
            const minutes = Math.floor(delta_in_seconds / 60) % 60;
            const hours = Math.floor(delta_in_seconds / 60 / 60) % 24;
            const days = Math.floor(delta_in_seconds / 60 / 60 / 24);
            set_deadline(days + 'd ' + hours.toString().padStart(2, "0") + 'h ' + minutes.toString().padStart(2, "0") + 'm ' + seconds.toString().padStart(2, "0") + 's');
        } else {
            set_started(true);
            set_deadline('tournament started');
        }
    }

    return (
        <div>
            {
                started ?
                    <div>
                        <div className="ttc-text">
                            Final preparations!<br/><br/>
                            Please wait for a few seconds<br/><br/>
                            Game will start automatically
                        </div>
                    </div>
                    :
                    <div>
                        <div className="ttc-text">
                            come back in:
                        </div>
                        <div className="ttc-timer">
                            {deadline}
                        </div>
                    </div>
            }
        </div>
    );
}