import { useEffect, useState } from "react";
import { post_json } from "../../core/fetch";
import settings from "../../core/settings";
import { useWalletStore } from "../../stores/wallet_store";
import { MetaMaskLogin } from "../meta_mask_login";

import './accounts_page.css'

export function AccountsPage() {
    const [accounts, set_accounts] = useState<any[]>([]);
    const address = useWalletStore(state => state.address);

    useEffect(() => {
        fetch_accounts();
    }, []);

    useEffect(() => {
        if (address !== '') {
            post_json(settings.accounts_server_url + '/login', { address })
                .then(data => {
                    console.log(data);
                    fetch_accounts();
                });
        }
    }, [address]);

    const fetch_accounts = () => {
        post_json(settings.accounts_server_url + '/accounts', {})
            .then(data => {
                console.log(data);
                if (data.status === 200) {
                    if (data.data) {
                        set_accounts(data.data);
                    }
                }
            });
    }

    const on_refill = (account_id: string) => {
        post_json(settings.accounts_server_url + '/refill_account', { account_id })
            .then(data => {
                console.log(data);
                fetch_accounts();
            });
    }

    const on_delete = (account_id: string) => {
        post_json(settings.accounts_server_url + '/delete_account', { account_id })
            .then(data => {
                console.log(data);
                fetch_accounts();
            });
    }

    return (
        <div className="ap-root">
            {
                accounts.map((account, index) => {
                    return (
                        <div className="ap-account" key={index}>
                            {account.account_id.S} / {account.coins.N} / {account.address ? account.address.S : ''}
                            <button className="ap-account-button" onClick={() => on_refill(account.account_id.S)}>
                                refill
                            </button>
                            <button className="ap-account-button" onClick={() => on_delete(account.account_id.S)}>
                                delete
                            </button>
                        </div>
                    );
                })
            }
            {address === '' && <MetaMaskLogin />}
        </div>
    );
}