import { useState } from "react";
import { post_json } from "../../core/fetch";
import { sha256 } from "../../core/hash";
import settings from "../../core/settings";
import { validate_email } from "../../core/utils";
import { useAccountStore } from "../../stores/account_store";
import { doge_avatars } from "../avatars/doge_avatars";
import { OAuth2PFPDialog } from "./aouth2_pfp_dialog";

interface Props {
    on_close: any;
}

export function OAuth2SignupComponent(props: Props) {
    const [username, set_username] = useState('');
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');
    const [password2, set_password2] = useState('');
    const [avatars, set_avatars] = useState<any[]>(doge_avatars);
    const [avatar_index, set_avatar_index] = useState<number>(Math.floor(Math.random() * doge_avatars.length));
    const [error, set_error] = useState('');
    const [dialog_opened, set_dialog_opened] = useState(false);
    const set_access_token = useAccountStore(state => state.set_access_token);
    const fetch_account = useAccountStore(state => state.fetch_account);

    const on_signup = async () => {
        post_json(settings.accounts_server_url + '/api/auth/signup', {
            address: email,
            name: username,
            password: await sha256(password),
            small_avatar: avatars[avatar_index].small,
        }).then(data => {
            //console.log('/api/signup', data);
            if (data.status === 200) {
                set_access_token(data.token);
                fetch_account();
            } else {
                set_error('user exist')
            }
        });
    }

    const close_dialog = (data: any) => {
        if (data) {
            let new_avatars = [...avatars];
            new_avatars.push(data);
            set_avatars(new_avatars);
            set_avatar_index(new_avatars.length - 1);
        }
        set_dialog_opened(false);
    }

    const on_cancel = () => {
        props.on_close();
    }

    return (
        <div>
            <div>
                <div className='oap-text'>
                    Enter Your E-mail
                </div>
                <div>
                    <input className={validate_email(email) ? 'oap-input' : 'oap-input-error'} id="email" type="text" value={email} onChange={event => set_email(event.target.value.toLowerCase())} />
                </div>
                <div className='oap-error'>
                    {error}
                </div>
                <div className='oap-text'>
                    Enter a Password
                </div>
                <div>
                    <input className='oap-input' id="password" type="password" value={password} onChange={event => set_password(event.target.value)} />
                </div>
                <div className='oap-text'>
                    Confirm a Password
                </div>
                <div>
                    <input className={password === password2 ? 'oap-input' : 'oap-input-error'} id="password2" type="password" value={password2} onChange={event => set_password2(event.target.value)} />
                </div>
                <div className='oap-text'>
                    Enter Your Name
                </div>
                <div>
                    <input className='oap-input' id="user_name" type="text" value={username} onChange={event => set_username(event.target.value)} />
                </div>
                <div className='oap-big-space' />
                <div>
                    <div className='oap-text'>
                        Choose Your Fighter
                    </div>
                    <div className='oap-avatars-center'>
                        <div className='oap-avatars'>
                            {avatars.map((value, index) => {
                                return <div className={index === avatar_index ? 'oap-avatar-selected' : 'oap-avatar'} key={index}>
                                    <img className='oap-avatar-image' alt='avatar' src={value.small} onClick={() => set_avatar_index(index)} />
                                </div>
                            })}
                        </div>
                    </div>
                    <div className='oap-text'>
                        or
                    </div>
                    <button className="oap-button" onClick={() => set_dialog_opened(true)}>
                        Upload Own NFT
                    </button>
                </div>
                <div className='oap-big-space' />
                <div>
                    <button className='oap-button' onClick={on_signup} disabled={username === '' || !validate_email(email) || password === '' || password !== password2}>
                        Register Account
                    </button>
                </div>
                <div>
                    <button className='oap-button' onClick={on_cancel}>
                        Cancel
                    </button>
                </div>
            </div>
            {
                dialog_opened && <OAuth2PFPDialog on_close={close_dialog} />
            }
        </div>
    );
}