import { SubscriptionStateComponent } from "../subsctiption_state_component";

import './subscription_test_component.css'

interface Props {
    subscription: any;
}

export function SubscriptionTestComponent(props: Props) {
    return (
        <div className="stc-block">
            subscription_id: {props.subscription.subscription_id.S} <br />
            tournament_id: {props.subscription.tournament_id.S} <br />
            account_id: {props.subscription.account_id.S} <br />
            room_id: {props.subscription.room_id.S} <br />
            current_state: <SubscriptionStateComponent state={props.subscription.current_state.S} /> <br />
        </div>
    );
}