import { useEffect, useState } from 'react';
import { usePeersStore } from '../stores/peers_store';
import { useRoomStore } from '../stores/room_store';
import { useSessionStore } from '../stores/session_store';
import { PeerView } from './peer_view';
import { VoiceChatClient } from './voice_chat_client';
import * as events from '../core/events'

import './voice_chat_overlay.css'
import { VoiceChatSettings } from './voice_chat_settings';

interface Props {
    room_id: string;
}

let vc_client: any = null;

export function VoiceChatOverlay(props: Props) {
    const [connected, set_connected] = useState<boolean>(false);
    const [settings_opened, set_settings_opened] = useState<boolean>(false);
    //const [mic_volume, set_mic_volume] = useState<number>(80);
    const peers = usePeersStore(state => state.peers);
    const session_store = useSessionStore();
    const room_store = useRoomStore();

    useEffect(() => {
        //fetch_connected_devices();

        //navigator.mediaDevices.addEventListener('devicechange', on_device_change);
        events.on('voice_chat:connected', on_connected);
        events.on('voice_chat:settings:close', close_settings);
        //events.on('voice_chat:output:set', on_set_output_device);
        events.on('voice_chat:input:set', on_set_input_device);
        return () => {
            //navigator.mediaDevices.removeEventListener('devicechange', on_device_change);
            events.off('voice_chat:connected', on_connected);
            events.off('voice_chat:settings:close', close_settings);
            //events.off('voice_chat:output:set', on_set_output_device);
            events.off('voice_chat:input:set', on_set_input_device);
        };
    }, []);

    const on_connected = (value: boolean) => {
        set_connected(value);
    }

    const open_settings = () => {
        set_settings_opened(true);
    }

    const close_settings = () => {
        set_settings_opened(false);
    }

    const on_set_input_device = (device: any) => {
        //console.log('on_set_input_device', device, vc_client);
        if (vc_client) {
            vc_client.set_input_device(device);
        }
    }

    /*const on_device_change = (event: any) => {
        //console.log(event);
        fetch_connected_devices();
    }

    const fetch_connected_devices = () => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const filtered = devices.filter(device => device.kind === 'audioinput');
                //console.log('audioinput', filtered);
                //callback(filtered);
            });
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const filtered = devices.filter(device => device.kind === 'audiooutput');
                //console.log('audiooutput', filtered);
                //callback(filtered);
            });
    }*/

    const on_join = () => {
        vc_client = new VoiceChatClient(session_store.username, props.room_id);
        events.emit('room:error', '');
        vc_client.join();
    }

    const on_disconnect = () => {
        if (vc_client) {
            vc_client.close();
            set_connected(false);
            events.emit('room:error', '');
            vc_client = null;
        }
    }

    /*const on_mic_volume_change = (event: any) => {
        vc_client.set_mic_volume(event.target.value);
        set_mic_volume(event.target.value);
    }*/

    const on_mute = () => {
        if (vc_client) {
            vc_client.muteMic();
            room_store.set_muted(true);
        }
    }

    const on_unmute = () => {
        if (vc_client) {
            vc_client.unmuteMic();
            room_store.set_muted(false);
        }
    }

    return (
        <>
            <div className="vc-root">
                <div className='vc-title'>
                    Voice Chat
                </div>
                <div>
                    <div className="vc-name">
                        {session_store.username}
                    </div>
                    {!connected && <button className='vc-join-button' onClick={on_join}>
                        JOIN
                    </button>}
                    {connected &&
                        <div>
                            <div>
                                <button className='vc-join-button' onClick={on_disconnect}>
                                    DISCONNECT
                                </button>
                            </div>
                            <div>
                                <button className='vc-settings-button' onClick={open_settings}>
                                    SETTINGS
                                </button>
                            </div>
                            <div>
                                {room_store.muted ?
                                    <button className='vc-mute-button' onClick={on_unmute}>
                                        UNMUTE
                                    </button>

                                    :
                                    <button className='vc-mute-button' onClick={on_mute}>
                                        MUTE
                                    </button>
                                }
                            </div>
                            {/*<div>
                                <input
                                    type="range"
                                    id="mic_volume"
                                    name="mic_volume"
                                    min="0"
                                    max="100"
                                    value={mic_volume}
                                    step="5"
                                    onChange={on_mic_volume_change}
                                />
                            </div>*/}
                        </div>
                    }
                </div>
                <div>
                    State: {room_store.status}
                    {room_store.error !== '' &&
                        <div>
                            Error: {room_store.error}
                        </div>
                    }
                </div>
                <div>
                    Peers:
                    {peers.map((value: any, index: number) => {
                        return <PeerView key={index} peer_id={value.id} />
                    })}
                </div>
            </div>
            {settings_opened && <VoiceChatSettings />}
        </>
    );
}