import './game_page.css'
import { PasswordPage } from './password_page';
import { useSessionStore } from '../stores/session_store';
import GameIFrame from './game_iframe';
import { VoiceChatOverlay } from '../voice_chat/voice_chat_overlay';
import { TextChatOverlay } from './text_chat/text_chat_overlay';
import { nanoid } from 'nanoid';

interface Props {
    password?: boolean;
    game_url: string;
    game_title: string;
    company_title: string;
    use_voice_chat?: boolean;
    use_text_chat?: boolean;
    room_id: string;
    avatars?: boolean;
    wallet?: boolean;
    basic_avatars: any[];
}

export function GamePage(props: Props) {
    const token = useSessionStore(state => state.token)
    const account_id = nanoid();

    return (
        <div>
            {token === '' && props.password ?
                <PasswordPage
                    game_title={props.game_title}
                    avatars={props.avatars}
                    wallet={props.wallet}
                    basic_avatars={props.basic_avatars}
                />
                :
                <>
                    <GameIFrame
                        game_url={props.game_url}
                        game_title={props.game_title}
                        company_title={props.company_title}
                    />
                    {props.use_voice_chat && <VoiceChatOverlay room_id={props.room_id}/>}
                    {props.use_text_chat && <TextChatOverlay room_id={props.room_id} account_id={account_id}/>}
                </>
            }
        </div>
    );
}