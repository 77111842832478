import create from "zustand";

export interface IWalletStore {
    address: string;
    signature: string;
    provider: string;
};


export const useWalletStore = create<IWalletStore>((set) => {
    return {
        address: '',
        signature: '',
        provider: 'eth',
    }
})
