import './App.css';
import { MainPage } from './components/main_page';
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { GamePage } from './components/game_page';
import { WalletsTestPage } from './components/dev/wallets_test_page';
import { Buffer } from "buffer";
import { common_avatars } from './components/avatars/common_avatars';
import { doge_avatars } from './components/avatars/doge_avatars';
import { AccountsPage } from './components/dev/accounts_page';
import { DevTournamentsPage } from './components/dev/tournaments/dev_tournaments_page';
import { DevTournamentLobbyPage } from './components/dev/tournaments/dev_tournament_lobby_page';
import { P2PTestPage } from './components/p2p_test_page';
import { OneInchLoginPage } from './components/one_inch_login_page';
import { DevTournamentResultsPage } from './components/dev/tournaments/dev_tournament_results_page';
import { TournamentTestPage } from './components/dev/tournaments/test/tournament_test_page';
import { FFFLandingPage } from './components/parties/fff/fff_landing_page';
import { fff_avatars } from './components/avatars/fff_avatars';
import { TournamentPage } from './components/tournaments/tournament_page';
import { OAuth2AuthorizePage } from './components/oauth2/oauth2_authorize_page';
import { ClaimrPage } from './components/claimr/claimr_page';
import { KlinkPage } from './components/claimr/klink_page';
import { DemoPage } from './components/claimr/demo_page';
import { HMSTRSPage } from './components/claimr/hmstrs_page';
import { TestPage } from './components/claimr/test_page';

if (!window.Buffer) {
  window.Buffer = Buffer;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/blackjack" element={
          <GamePage
            password={true}
            game_url={'/games/bj/index.html'}
            game_title='blackjack'
            company_title='HMSTRS'
            room_id=''
            basic_avatars={common_avatars}
          />
        } />
        <Route path="/poker" element={
          <GamePage
            password={true}
            game_url={'/games/poker/index.html'}
            game_title='poker'
            company_title='HMSTRS'
            room_id=''
            basic_avatars={common_avatars}
          />
        } />
        <Route path="/doge/poker" element={
          <GamePage
            password={true}
            game_url={'/games/poker/index.html'}
            game_title='poker'
            company_title='The Doge NFT'
            use_voice_chat={true}
            avatars={true}
            wallet={true}
            room_id='dogeroom'
            basic_avatars={doge_avatars}
          />
        } />
        <Route path="/degenape/poker" element={
          <GamePage
            password={true}
            game_url={'/games/degenape/poker/index.html'}
            game_title='poker'
            company_title='Degen Ape Academy'
            room_id='degenape'
            basic_avatars={common_avatars}
          />
        } />
        <Route path="/1inch/poker" element={<OneInchLoginPage />} />
        <Route path="/ssv/poker" element={
          <GamePage
            password={true}
            game_url={'/games/ssv/poker/index.html'}
            game_title='poker'
            company_title='SSV'
            use_voice_chat={true}
            avatars={true}
            wallet={true}
            room_id='ssvcroom'
            basic_avatars={common_avatars}
          />
        } />
        <Route path="/vc/poker" element={
          <GamePage
            password={false}
            game_url={'/games/int/poker/index.html'}
            game_title='poker'
            company_title='HMSTRS'
            use_voice_chat={true}
            use_text_chat={true}
            avatars={true}
            room_id='hmstrsrm'
            basic_avatars={common_avatars}
          />
        } />
        <Route path="/binance/poker" element={
          <GamePage
            password={true}
            game_url={'/games/binance/poker/index.html'}
            game_title='poker'
            company_title='BINANCE'
            use_voice_chat={true}
            avatars={true}
            room_id='binancer'
            wallet={true}
            basic_avatars={common_avatars}
          />
        } />
        <Route path="/fff/poker" element={
          <GamePage
            password={true}
            game_url={'/games/fff/poker/index.html'}
            game_title='poker'
            company_title='FAST FOOD FRENS'
            use_voice_chat={true}
            avatars={true}
            room_id='fastroom'
            basic_avatars={fff_avatars}
          />
        } />
        <Route path="/wallets" element={<WalletsTestPage />} />
        <Route path="/p2p" element={<P2PTestPage />} />
        <Route path="/accounts" element={<AccountsPage />} />
        <Route path="/dev/tournaments" element={<DevTournamentsPage />} />
        <Route path="/dev/tournament/:tid" element={<DevTournamentLobbyPage />} />
        <Route path="/dev/t/:tid" element={<DevTournamentResultsPage />} />
        <Route path="/ttp" element={<TournamentTestPage />} />
        <Route path="/tournament" element={
          <GamePage
            game_url={'/games/tournament/poker/index.html'}
            game_title='poker'
            company_title='HMSTRS'
            use_voice_chat={true}
            avatars={true}
            room_id='trnmntt'
            wallet={true}
            basic_avatars={common_avatars}
          />
        } />
        <Route path="/tournament/:tid" element={<TournamentPage />} />
        <Route path="/poker/fastfoodfrens" element={<FFFLandingPage />} />
        <Route path="/api/oauth2/authorize" element={<OAuth2AuthorizePage />} />
        {/*<Route path="/magic" element={<MagicAuthPage />} />*/}

        <Route path="/claimr" element={<ClaimrPage />} />
        <Route path="/klinkfinance" element={<KlinkPage />} />
        <Route path="/test" element={<TestPage />} />
        <Route path="/hmstrs" element={<HMSTRSPage />} />
        <Route path="/demo" element={<DemoPage />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
