import create from "zustand";
import { get_json } from "../core/fetch";
import settings from "../core/settings";

interface AccountState {
    access_token: string;
    account: any;
    connection_error: boolean;
    set_access_token: any;
    fetch_account: any;
    reset: any;
}

let retries = 0;

export const useAccountStore = create<AccountState>((set, get) => ({
    access_token: localStorage.getItem('hmstrs-access-token') || '',
    account: null,
    connection_error: false,
    set_access_token: (access_token: string) => {
        localStorage.setItem('hmstrs-access-token', access_token);
        set({
            access_token
        });
    },
    fetch_account: async () => {
        if (get().access_token === '')
            return;

        let data = null;
        try {
            data = await get_json(settings.accounts_server_url + '/users/me', get().access_token);
        } catch (err) {
            console.log('fetch account error', err);
        }
        if (data && data.status === 200) {
            if (data.data) {
                set({
                    account: data.data,
                });
                retries = 0;
            }
        } else {
            retries++;
            if (retries >= 10) {
                set({
                    connection_error: true,
                });    
            }
            else {
                setTimeout(() => {
                    get().fetch_account();
                }, 1000);
            }
        }
    },
    reset: () => {
        localStorage.removeItem('hmstrs-access-token');
        set({
            access_token: '',
            account: null,
        });
    },
}));