import './main_page.css'

export function MainPage() {
    return (
        <div className='mp-root'>
            <div className='mp-page'>
                <div className='mp-container'>
                    <div className='mp-center'>
                    </div>
                </div>
            </div>
            <div className='mp-page'>
                <div className='mp-container'>
                    <div className='mp-center'>
                        <div className='mp-logo'>
                            HMSTRS
                        </div>
                        <img src="hmstrs.png" className="mp-main_image" alt="hamsters"/>
                        <div className='mp-mission'>
                            we create play-and-earn games for web3 communities
                        </div>
                        <div className='mp-mail'>
                            <a className='mp-link' href="mailto:yo@hmstrs.com">yo@hmstrs.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}