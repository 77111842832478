import { useEffect, useRef, useState } from "react";
import * as events from '../../core/events'
import './cards_history_overlay.css'

const suit_symbols = ['♥', '♦', '♣', '♠'];
const rank_symbols = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
const player_combination: any = {
    'high_card': 'HIGH CARD',
    'pair': 'PAIR',
    'two_pairs': 'TWO PAIRS',
    'three_of_a_kind': 'THREE OF A KIND',
    'straight': 'STRAIGHT',
    'flush': 'FLUSH',
    'full_house': 'FULL HOUSE',
    'four_of_a_kind': 'FOUR OF A KIND',
    'straight_flush': 'STRAIGHT FLUSH',
    'royal_flush': 'ROYAL FLUSH',
};

export function CardsHistoryOverlay() {
    const resultsRef = useRef<HTMLDivElement>(null);
    const [opened, set_opened] = useState(false);
    const [results, set_results] = useState<any[]>([]);

    useEffect(() => {
        events.on('session:results', on_session_results_event);
        return () => {
            events.off('session:results', on_session_results_event);
        }
    }, []);

    useEffect(() => {
        if (resultsRef.current) {
            resultsRef.current.scrollTop = resultsRef.current.scrollHeight;
        }
    }, [opened]);

    const on_session_results_event = (data: any) => {
        //console.log('on_session_results_event', data);
        let result = { ...data };
        for (let winner of result.winners) {
            let hand = result.hands.find((entry: any) => {
                return entry.client_id === winner.client_id;
            });
            if (hand) {
                hand.bank = winner.bank;
            }
        }
        set_results(results => [...results, result]);
        if (resultsRef.current) {
            resultsRef.current.scrollTop = resultsRef.current.scrollHeight;
        }
    }

    const combination_name = (type: string) => {
        return player_combination[type];
    }

    return (
        <div className={"cho-root" + (opened ? " cho-opened" : "")}>
            <div className="cho-title">
                Cards History
            </div>
            {opened ?
                <button className="cho-button" onClick={() => set_opened(false)}>
                    CLOSE
                </button>
                :
                <button className="cho-button" onClick={() => set_opened(true)}>
                    OPEN
                </button>
            }
            <div className="cho-container">
                {opened &&
                    <>
                        <div className="cho-results-block" ref={resultsRef}>
                            {
                                results.map((data, index) => {
                                    return (
                                        <div className="cho-results" key={index}>
                                            round {data.round_index}
                                            {
                                                data.hands.map((hand: any, index: number) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className="cho-player-name">
                                                                {hand.name}
                                                                {
                                                                    hand.bank &&
                                                                    <>
                                                                        {' +' + hand.bank}
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className="cho-combination">
                                                                {
                                                                    hand.combination.cards.map((card: any, index: number) => {
                                                                        return (
                                                                            <span key={index}>
                                                                                {rank_symbols[card.rank]}
                                                                                <span className={card.suit < 2 ? 'cho-red-suit' : 'cho-black-suit'}>
                                                                                    {suit_symbols[card.suit]}
                                                                                </span>
                                                                            </span>
                                                                        );
                                                                    })
                                                                }
                                                                <span>
                                                                    {' - ' + combination_name(hand.combination.type)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
}