export const common_avatars = [
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/coldplunge.jpg',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-coldplunge.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/kumqwat.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-kumqwat.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/adi.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-adi.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/max.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-max.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/kumqwat-o.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-kumqwat-o.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-0-0.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-0.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-0-1.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-0.png',
    },
    /*{
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-0-2.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-0.png',
    },*/
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-0-3.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-0.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-1-0.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-1.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-1-1.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-1.png',
    },
    /*{
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-1-2.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-1.png',
    },*/
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-1-3.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-1.png',
    },
    /*{
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-2-0.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-2.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-2-1.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-2.png',
    },*/
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-2-2.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-2.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-2-3.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-2.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-3-0.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-3.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-3-1.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-3.png',
    },
    /*{
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-3-2.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-3.png',
    },*/
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-3-3.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-3.png',
    },
]