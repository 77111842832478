import { useEffect, useState } from 'react';
import { post_json } from '../core/fetch';
import settings from '../core/settings';
import { useSessionStore } from '../stores/session_store';
import { useWalletStore } from '../stores/wallet_store';
import { VoiceChatOverlay } from '../voice_chat/voice_chat_overlay';
import { doge_avatars } from './avatars/doge_avatars';
import GameIFrame from './game_iframe';
import { MetaMaskLogin } from './meta_mask_login';
import './one_inch_login_page.css'

export function OneInchLoginPage() {
    const address = useWalletStore(state => state.address);
    const [logged_in, set_logged_in] = useState(false);
    const username = useSessionStore(state => state.username);
    const [avatar_index, set_avatar_index] = useState<number>(Math.floor(Math.random() * doge_avatars.length));
    const [avatars, set_avatars] = useState<any[]>(doge_avatars);

    useEffect(() => {
        if (address !== '') {
            const body = {
                address: address,//'0xdac3adc7098fba22ed1d2fcd7dffd8e6b9c6ca19',
                offset: 0,
                limit: 20,
            };
            post_json(settings.accounts_server_url + '/assets', body)
                .then(data => {
                    console.log(data);
                    if (data.data.status === 200) {
                        if (data.data.data && data.data.data.assets) {
                            let new_avatars = [...doge_avatars];
                            for (let entry of data.data.data.assets) {
                                new_avatars.push({
                                    small: entry.image_url,
                                    sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-3.png',
                                });
                            }
                            set_avatars(new_avatars);
                        }
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const on_name_change = (event: any) => {
        useSessionStore.setState({ username: event.target.value });
    }

    const on_select_avatar = (index: number) => {
        set_avatar_index(index);
    }

    const on_join = () => {
        useSessionStore.setState({
            username: username,
            avatars: avatars[avatar_index],
        });
        set_logged_in(true);
    }

    return (
        <div className='oilp-root'>
            {address === '' ?
                <div className='oilp-vertical-center'>
                    <MetaMaskLogin />
                </div>
                :
                <div>
                    {logged_in ?
                        <>
                            <GameIFrame
                                game_url={'/games/1inch/poker/index.html'}
                                game_title='poker'
                                company_title='1inch'
                            />
                            <VoiceChatOverlay room_id='1nchroom' />
                        </>
                        :
                        <div>
                            <div className='oilp-logo'>
                                1inch
                            </div>
                            <div className='oilp-game'>
                                POKER
                            </div>
                            <div className='oilp-big_space' />
                            <div className='oilp-text'>
                                NAME
                            </div>
                            <div>
                                <input className='oilp-user_input' id="user_name" type="text" onChange={on_name_change} />
                            </div>
                            <div className='oilp-big_space' />
                            <div>
                                <div className='oilp-small-text'>
                                    Choose your fighter
                                </div>
                                <div className='oilp-avatars-center'>
                                    <div className="oilp-avatars">
                                        {avatars.map((value, index) => {
                                            return <div className={index === avatar_index ? 'oilp-avatar-selected' : 'oilp-avatar'} key={index}>
                                                <img className='oilp-avatar-image' alt='avatar' src={value.small} onClick={() => on_select_avatar(index)} />
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className='oilp-big_space' />
                            </div>
                            <div>
                                <button className='oilp-join_button' onClick={on_join} disabled={username === ''}>
                                    GO!
                                </button>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}