import { useEffect, useState } from 'react';
import { sha256 } from '../core/hash';
import settings from '../core/settings';
import './password_page.css'
import { useSessionStore } from '../stores/session_store';
import { useWalletStore } from '../stores/wallet_store';
//import { WalletConnectOverlay } from './wallet_connect_overlay';
import { post_json } from '../core/fetch';




interface Props {
    game_title: string;
    avatars?: boolean;
    wallet?: boolean;
    basic_avatars: any[];
}

export function PasswordPage(props: Props) {
    const address = useWalletStore(state => state.address);
    const username = useSessionStore(state => state.username);
    const password = useSessionStore(state => state.password);
    const [avatars, set_avatars] = useState<any[]>(props.basic_avatars);
    const [avatar_index, set_avatar_index] = useState<number>(Math.floor(Math.random() * props.basic_avatars.length));

    useEffect(() => {
        if (address !== '') {
            const body = {
                address: address,//'0xdac3adc7098fba22ed1d2fcd7dffd8e6b9c6ca19',
                offset: 0,
                limit: 20,
            };
            post_json(settings.accounts_server_url + '/assets', body)
                .then(data => {
                    console.log(data);
                    if (data.data.status === 200) {
                        if (data.data.data && data.data.data.assets) {
                            let new_avatars = [...avatars];
                            for (let entry of data.data.data.assets) {
                                new_avatars.push({
                                    small: entry.image_url,
                                    sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-3.png',
                                });
                            }
                            set_avatars(new_avatars);
                        }
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const onJoin = async () => {
        const body = {
            passwordHash: await sha256(password),
        };

        post_json(settings.accounts_server_url + '/auth', body)
            .then(data => {
                if (data.accepted) {
                    useSessionStore.setState({
                        username: username,
                        token: data.token,
                        avatars: avatars[avatar_index],
                        small_avatar: avatars[avatar_index].small,
                    });
                }
            });
    }

    const onNameChange = (event: any) => {
        useSessionStore.setState({ username: event.target.value });
    }

    const onPasswordChange = (event: any) => {
        useSessionStore.setState({ password: event.target.value });
    }

    const onSelectAvatar = (index: number) => {
        set_avatar_index(index);
    }

    return (
        <>
            <div className='root'>
                <div className='logo'>
                    HMSTRS
                </div>
                <div className='game'>
                    {props.game_title}
                </div>
                <div className='big_space' />
                <div className='text'>
                    NAME
                </div>
                <div>
                    <input className='user_input' id="user_name" type="text" onChange={onNameChange} />
                </div>
                <div className='small_space' />
                <div className='text'>
                    PASS
                </div>
                <div>
                    <input className='user_input' id="user_password" type="password" onChange={onPasswordChange} />
                </div>
                <div className='small_space' />
                {props.avatars &&
                    <div>
                        <div className='small-text'>
                            Choose your fighter
                        </div>
                        <div className='avatars-center'>
                            <div className="avatars">
                                {avatars.map((value, index) => {
                                    return <div className={index === avatar_index ? 'avatar-selected' : 'avatar'} key={index}>
                                        <img className='avatar-image' alt='avatar' src={value.small} onClick={() => onSelectAvatar(index)} />
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className='small_space' />
                    </div>
                }
                <div>
                    <button className='join_button' onClick={onJoin} disabled={username === '' || password === ''}>
                        JOIN
                    </button>
                </div>
            </div>
            {/*props.wallet && <WalletConnectOverlay />*/}
        </>
    );
}