import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { post_json } from '../../../core/fetch';
import settings from '../../../core/settings';
import storage from '../../../core/storage';
import { useWalletStore } from '../../../stores/wallet_store';
import { SubscriptionStateComponent } from './subsctiption_state_component';

import './dev_tournament_results_page.css'

export function DevTournamentResultsPage() {
    const { tid } = useParams();
    const [tournament, set_tournament] = useState<any>(null);
    const [subscriptions, set_subscriptions] = useState<any[]>([]);
    const [local_datetime, set_local_datetime] = useState('');

    const [account_id, set_account_id] = useState('');
    const address = useWalletStore(state => state.address);

    useEffect(() => {
        const tmp_aid = localStorage.getItem('tmp-aid');
        set_account_id(tmp_aid ? tmp_aid : '');

        fetch_tournament();
        fetch_subscriptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!tournament) {
            return;
        }

        const date = new Date(tournament.datetime.S);
        set_local_datetime(date.toLocaleString());
    }, [tournament]);

    const fetch_tournament = () => {
        post_json(settings.tournaments_server_url + '/tournament', {
            tournament_id: tid
        }, storage.jwt).then(data => {
            console.log('fetch_tournament', data);
            if (data.status === 200) {
                if (data.data) {
                    set_tournament(data.data);
                }
            }
        });
    }

    const fetch_subscriptions = () => {
        post_json(settings.tournaments_server_url + '/subscriptions', {
            tournament_id: tid
        }, storage.jwt).then(data => {
            console.log(data);
            if (data.status === 200) {
                if (data.data) {
                    set_subscriptions(data.data);
                }
            }
        });
    }



    return (
        <div className="trp-root">
            <div className="trp-address">
                ID: {account_id}<br />
                ADDRESS: {address}
            </div>
            <div>
                tournament_id: {tid} <br />
                {tournament && <div>
                    name: {tournament.name.S} <br />
                    datetime: {local_datetime}
                </div>}
            </div>
            <div className="trp-title">
                SUBSCRIPTIONS
            </div>
            {
                subscriptions.map((subscription, index) => {
                    return (
                        <div className={subscription.account_id.S === account_id ?
                            "trp-own-subscription" : "trp-subscription"} key={index}>
                            subscription_id: {subscription.subscription_id.S} <br />
                            tournament_id: {subscription.tournament_id.S} <br />
                            account_id: {subscription.account_id.S} <br />
                            current_state: <SubscriptionStateComponent state={subscription.current_state.S} /> <br />
                        </div>
                    );
                })
            }
        </div>
    );
}