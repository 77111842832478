import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { post_json } from "../../../core/fetch";
import settings from "../../../core/settings";
import { useAccountStore } from "../../../stores/account_store";

import './dev_tournament_lobby_page.css'

export function DevTournamentLobbyPage() {
    const { tid } = useParams();
    const [tournament, set_tournament] = useState<any>(null);
    const [players, set_players] = useState<any[]>([]);
    const [deadline, set_deadline] = useState('');
    const [local_datetime, set_local_datetime] = useState('');

    const access_token = useAccountStore(state => state.access_token);
    const account = useAccountStore(state => state.account);
    const set_access_token = useAccountStore(state => state.set_access_token);
    const fetch_account = useAccountStore(state => state.fetch_account);

    useEffect(() => {
        if (access_token !== '') {
            set_access_token(access_token);
            fetch_account();
        }

        fetch_tournament();
        fetch_players();

        //setInterval(fetch_players, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!tournament) {
            return;
        }

        const date = new Date(tournament.datetime.S);
        set_local_datetime(date.toLocaleString());

        const delta = get_delta();
        console.log(tournament.datetime.S, delta);
        if (delta < 0) {
            return;
        }

        const tick_interval_id = setInterval(on_tick, 1000);

        return () => {
            clearInterval(tick_interval_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournament]);

    useEffect(() => {
        if (!account) {
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const get_delta = () => {
        const now = Date.now();
        const dl = new Date(tournament.datetime.S).getTime();
        return dl - now;
    }

    const on_tick = () => {
        const delta = get_delta();
        if (delta >= 0) {
            const delta_in_seconds = Math.floor(delta / 1000);
            const seconds = delta_in_seconds % 60;
            const minutes = Math.floor(delta_in_seconds / 60) % 60;
            const hours = Math.floor(delta_in_seconds / 60 / 60) % 24;
            const days = Math.floor(delta_in_seconds / 60 / 60 / 24);
            set_deadline(days + 'd ' + hours.toString().padStart(2, "0") + 'h ' + minutes.toString().padStart(2, "0") + 'm ' + seconds.toString().padStart(2, "0") + 's');
        } else {
            set_deadline('tournament started');
        }
    }

    const fetch_tournament = () => {
        post_json(settings.tournaments_server_url + '/tournament', {
            tournament_id: tid
        }, access_token).then(data => {
            console.log('fetch_tournament', data);
            if (data.status === 200) {
                if (data.data) {
                    set_tournament(data.data);
                }
            }
        });
    }

    const fetch_players = () => {
        post_json(settings.tournaments_server_url + '/players', {
            tournament_id: tid
        }, access_token).then(data => {
            console.log('fetch_players', data);
            if (data.status === 200) {
                if (data.data) {
                    set_players(data.data);
                }
            }
        });
    }

    const on_start_tournament = (tournament_id: string) => {
        post_json(settings.tournaments_server_url + '/start_tournament', {
            tournament_id
        }, access_token).then(data => {
            console.log(data);
            fetch_tournament();
        });
    }

    const on_close_tournament = (tournament_id: string) => {
        post_json(settings.tournaments_server_url + '/close_tournament', {
            tournament_id
        }, access_token).then(data => {
            console.log(data);
            fetch_tournament();
        });
    }

    return (
        <div className="tlp-root">
            <div>
                <div className="tlp-title">
                    TOURNAMENT LOBBY
                </div>
                <div>
                    {account && <div>
                        name: {account.name}
                    </div>}
                </div>
                <div>
                    tournament_id: {tid} <br />
                    {tournament && <div>
                        name: {tournament.name.S} <br />
                        datetime: {local_datetime}
                    </div>}
                </div>
                <div>
                    Time before start: {deadline}
                </div>
                <div>
                    Players online: {players.length}
                </div>
                {tournament && tournament.current_state.S === 'created' && <button className="tlp-button" onClick={() => on_start_tournament(tid || '')}>
                    start
                </button>}
                {tournament && tournament.current_state.S === 'booked' && <button className="tlp-button" onClick={() => on_close_tournament(tid || '')}>
                    close
                </button>}

            </div>
            {
                players.map((player, index) => {
                    return (
                        <div className='tlp-player' key={index}>
                            <span className='tlp-col'>{player.username}</span>
                            <span className='tlp-col'>{player.current_state}</span>
                        </div>
                    );
                })
            }
        </div>
    );
}