import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import storage from '../../core/storage';
import { useSessionStore } from '../../stores/session_store';
import { useWalletStore } from '../../stores/wallet_store';
import { TournamentComponent } from './tournament_component';
//import { TournamentLoginPage } from './tournament_login_page';
import './tournament_page.css'
import { AuthPage } from '../auth/auth_page';

export function TournamentPage() {
    const { tid } = useParams();
    const account_id = useSessionStore(state => state.account_id);
    const [ready, set_ready] = useState(false);

    useEffect(() => {
        const tour_account_id = localStorage.getItem('tour-account-id');
        const tour_username = localStorage.getItem('tour-username');
        const tour_address = localStorage.getItem('tour-address');
        const tour_avatar = localStorage.getItem('tour-avatar');
        const tour_jwt = localStorage.getItem('tour-jwt');

        if (tour_account_id &&
            tour_account_id !== 'undefined' &&
            tour_username &&
            tour_address &&
            tour_avatar &&
            tour_jwt) {
            useWalletStore.setState({
                address: tour_address
            });
            useSessionStore.setState({
                account_id: tour_account_id,
                username: tour_username,
                small_avatar: tour_avatar,
            });
            storage.jwt = tour_jwt;
        }
        set_ready(true);
    }, []);

    return (
        <div className='tp-blue-root'>
            <div className='tp-blue-background'>
                <div className='tp-blue-card-0'/>
                <div className='tp-blue-card-1'/>
                <div className='tp-blue-card-2'/>
                <div className='tp-blue-card-3'/>
                <div className='tp-blue-card-4'/>
            </div>
            <div className='tp-blue-content'>
                <div className='tp-blue-header'>
                    The DOGE NFT
                </div>
                <div className='tp-blue-game-text'>
                    poker
                </div>
                <div className='tp-blue-game-text'>
                    tournament
                </div>
                {
                    ready &&
                    <div>
                        {
                            account_id !== '' ?
                                <TournamentComponent tournament_id={tid ? tid : ''} />
                                :
                                <>
                                    {
                                        /*<TournamentLoginPage />*/
                                    }
                                    <AuthPage />
                                </>
                        }
                    </div>
                }
                {/*<div className="fff-tp-footer">
                    by HMSTRS
            </div>*/}
            </div>
        </div>
    );
}