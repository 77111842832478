import { useState } from 'react';
import { post_json } from '../../core/fetch';
import settings from '../../core/settings';
import { validate_email } from '../../core/utils';
import './auth_recover_dialog.css'

interface Props {
    on_close: any;
}

export function AuthRecoverDialog(props: Props) {
    const [email, set_email] = useState('');

    const on_ok = () => {
        post_json(settings.accounts_server_url + '/api/recover', {
            address: email,
            link: window.location.href,
        }).then(() => {
            props.on_close(true);
        });
    }

    const on_cancel = () => {
        props.on_close(false); 
    }

    return (
        <div className='apd-root'>
            <div className='apd-dialog'>
                <div className='apd-title'>
                    RECOVER PASSWORD
                </div>
                <div className='apd-small_space' />
                <div className='apd-text'>
                    ENTER YOUR EMAIL
                </div>
                <div>
                    <input className={validate_email(email) ? 'apd-input' : 'apd-input-error'} id="email" type="text" value={email} onChange={event => set_email(event.target.value.toLowerCase())} />
                </div>
                <div className='apd-small_space' />
                <div>
                    <button className='apd-button' onClick={on_ok} disabled={!validate_email(email)}>
                        OK
                    </button>
                    <button className='apd-button' onClick={on_cancel}>
                        CANCEL
                    </button>
                </div>
                <div className='apd-small_space' />
            </div>
        </div>
    );
}