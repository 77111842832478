import { useState } from 'react';
import { post_json } from '../../../../core/fetch';
import settings from '../../../../core/settings';
import storage from '../../../../core/storage';
import { useWalletStore } from '../../../../stores/wallet_store';
import { DevLoginPage } from '../../dev_login_page';

import './tournament_test_page.css'
import { TournamentTestComponent } from './tournament_test_component';

const table_types = [
    { key: '5_seats', value: 'Five Seats', max_seats: 5 },
    { key: '6_seats', value: 'Six Seats', max_seats: 6 },
]

const blind_types = [
    { key: '1_2', value: 'small 1 / big 2', small_blind: 1, big_blind: 2 },
    { key: '5_10', value: 'small 5 / big 10', small_blind: 5, big_blind: 10 },
    { key: '25_50', value: 'small 25 / big 50', small_blind: 25, big_blind: 50 },
]

export function TournamentTestPage() {
    const [players_count, set_players_count] = useState<number>(60);
    const [buy_in, set_buy_in] = useState<number>(1000);
    const [table_type_index, set_table_type_index] = useState<number>(1);
    const [blind_type_index, set_blind_type_index] = useState<number>(1);
    const [tournaments, set_tournaments] = useState<any[]>([]);
    const address = useWalletStore(state => state.address);

    const on_run = () => {
        create_tournament();
    }

    const create_tournament = () => {
        const date = new Date(Date.now() + 1000 * 60);
        date.setMilliseconds(0);
        date.setSeconds(0);
        const name = 'test tournament';

        post_json(settings.tournaments_server_url + '/create_tournament', {
            name,
            datetime: date.toISOString(),
            max_players: players_count,
            buy_in,
            small_blind: blind_types[blind_type_index].small_blind,
            big_blind: blind_types[blind_type_index].big_blind,
            max_seats: table_types[table_type_index].max_seats,
        }, storage.jwt).then(data => {
            console.log(data);
            set_tournaments([...tournaments, data.data]);
        });
    }

    const delete_tournaments = () => {
        for (const tournament of tournaments) {
            post_json(settings.tournaments_server_url + '/delete_subscriptions', {
                tournament_id: tournament.tournament_id
            }, storage.jwt).then(data => {
                console.log(data);
            });
            post_json(settings.tournaments_server_url + '/delete_tournament', {
                tournament_id: tournament.tournament_id
            }, storage.jwt).then(data => {
                console.log(data);
            });
        }
        set_tournaments([]);
    }

    const next_round = () => {
        const tournament = tournaments[tournaments.length - 1];

        const date = new Date(Date.now() + 1000 * 60);
        date.setMilliseconds(0);
        date.setSeconds(0);
        const name = 'test tournament ' + tournaments.length;

        post_json(settings.tournaments_server_url + '/create_tournament', {
            name,
            datetime: date.toISOString(),
            max_players: players_count,
            buy_in,
            parent_id: tournament.tournament_id,
            small_blind: blind_types[blind_type_index].small_blind,
            big_blind: blind_types[blind_type_index].big_blind,
            max_seats: table_types[table_type_index].max_seats,
        }, storage.jwt).then(data => {
            console.log(data);
            set_tournaments([...tournaments, data.data]);
        });
    }

    return (
        <div className='ttp-root'>
            <div className='ttp-head-title'>
                TOURNAMENT TEST
            </div>
            {
                address === '' ?
                    <div>
                        <DevLoginPage />
                    </div>
                    :
                    <div>
                        {tournaments.length === 0 &&
                            <div className='ttp-input-title'>
                                <button className="ttp-button" onClick={on_run}>
                                    RUN
                                </button>
                            </div>
                        }
                        {tournaments.length > 0 &&
                            <button className="ttp-button" onClick={() => delete_tournaments()}>
                                delete
                            </button>
                        }
                        {
                            tournaments.map((tournament, index) => {
                                return (
                                    <div className='ttp-input-title' key={index}>
                                        <TournamentTestComponent tournament={tournament} />
                                    </div>
                                );
                            })
                        }
                        {tournaments.length === 0 &&
                            <div className='ttp-input-title'>
                                players count
                                <input className='ttp-input' id='tournament-name' type="number" value={players_count} onChange={(event) => set_players_count(parseInt(event.target.value))} />
                            </div>
                        }
                        <div className='ttp-input-title'>
                            table type
                            <select className='ttp-select' value={table_types[table_type_index].key} onChange={(event) => set_table_type_index(event.target.selectedIndex)}>
                                {
                                    table_types.map((table_type, index) => {
                                        return (
                                            <option value={table_type.key} key={index}>{table_type.value}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <div className='ttp-input-title'>
                            buy-in
                            <input className='ttp-input' id='tournament-name' type="number" value={buy_in} onChange={(event) => set_buy_in(parseInt(event.target.value))} />
                        </div>
                        <div className='ttp-input-title'>
                            small/big blinds type
                            <select className='ttp-select' value={blind_types[blind_type_index].key} onChange={(event) => set_blind_type_index(event.target.selectedIndex)}>
                                {
                                    blind_types.map((blind_type, index) => {
                                        return (
                                            <option value={blind_type.key} key={index}>{blind_type.value}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        {tournaments.length > 0 &&
                            <button className="ttp-button" onClick={next_round}>
                                next round
                            </button>
                        }
                    </div>
            }
        </div>
    );
}