import create from "zustand";

interface SessionState {
    username: string;
    password: string;
    token: string; // TODO: mrodionov - please remove
    small_avatar: string;
    avatars: any;
    account_id: string;
    tournament_id: string;
    room_id: string;
    subscription_id: string;
    invitation_key: string;
}

export const useSessionStore = create<SessionState>((set, get) => ({
    username: '',
    password: '',
    token: '',
    avatars: {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/h-avatar-1.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-1.png'
    },
    small_avatar: '',
    account_id: '',
    tournament_id: '',
    room_id: '',
    subscription_id: '',
    invitation_key: '',
}));