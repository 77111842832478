import hark from 'hark';
import { useEffect, useRef, useState } from "react";
import * as events from '../core/events'
import { usePeersStore } from '../stores/peers_store';

import './peer_view.css'

interface Props {
    peer_id: string;
}

export function PeerView(props: Props) {
    const audio_ref = useRef<any>(null);
    const [track_entity, set_track_entity] = useState<any>(null);
    const peer = usePeersStore(state => state.peers.find(peer => props.peer_id === peer.id));
    const [hark_entity, set_hark_entity] = useState<any>(null);
    const [hark_volume, set_hark_volume] = useState<number>(0);

    useEffect(() => {
        events.on('consumers:add', _on_set_consumer);
        events.on('consumers:remove', _on_set_consumer);
        events.on('voice_chat:output:set', on_set_output_device);

        return () => {
            events.off('consumers:add', _on_set_consumer);
            events.off('consumers:remove', _on_set_consumer);                
            events.off('voice_chat:output:set', on_set_output_device);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const on_set_output_device = (device: any) => {
        if (audio_ref.current) {
            if (typeof audio_ref.current.sinkId !== 'undefined') {
                audio_ref.current.setSinkId(device.deviceId);
            }
        }
    }

    const _on_set_consumer = (data: any) => {
        if (data.peer_id === props.peer_id) {
            let _track = data?.consumer?.track;
            _set_track(_track);    
        }
    }

    const _set_track = (_track: any) => {
        if (track_entity === _track)
            return;

        set_track_entity(_track);

        _stop_hark();

        if (_track) {
            console.log('set_track');

            const stream = new MediaStream();

            stream.addTrack(_track);
            if (audio_ref.current) {
                audio_ref.current.srcObject = stream;
                audio_ref.current.play()
                    .catch((error: any) => console.log('audioElem.play() failed:%o', error));
                _start_hark(stream);
            }
        }
        else {
            if (audio_ref.current) {
                audio_ref.current.srcObject = null;
            }
        }
    }

    const _stop_hark = () => {
        if (hark_entity) {
            hark_entity.stop();
            set_hark_entity(null);
        }
    }

    const _start_hark = (stream: MediaStream) => {
        if (!stream.getAudioTracks()[0]) {
            console.log('given stream has no audio track');
        }

		let _hark = hark(stream, { play: false });

		_hark.on('volume_change', (dBs: any, threshold: any) =>
		{
			// The exact formula to convert from dBs (-100..0) to linear (0..1) is:
			//   Math.pow(10, dBs / 20)
			// However it does not produce a visually useful output, so let exagerate
			// it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
			// minimize component renderings.
			let audioVolume = Math.round(Math.pow(10, dBs / 85) * 20);

			if (audioVolume === 1)
				audioVolume = 0;

			if (audioVolume !== hark_volume)
				set_hark_volume(audioVolume);
		});

        set_hark_entity(_hark);
    }

    return (
        <div>
            {peer?.name}
            <div style={{ width: '200px'}}>
                <div className='pv-volume' style={{ width: `${hark_volume * 10}px` }}/>
            </div>
            <audio
                ref={audio_ref}
                autoPlay
                playsInline
                //muted={isMe || audioMuted}
                controls={false}
            />
        </div>
    );
}