import React, { RefObject } from "react";
import { useSessionStore } from "../../stores/session_store";

import * as events from '../../core/events'

import './tournament_iframe.css'

interface Props {
    account_id: string;
    room_id: string;
    invitation_key: string;
    subscription_id: string;
    tournament_id: string;
}

export default class TournamentIFrame extends React.Component<Props, any> {
    iframeRef: RefObject<HTMLIFrameElement>;

    constructor(props: Props) {
        super(props);

        this.iframeRef = React.createRef();
    }

    _on_iframe_event = this._on_iframe_message.bind(this);

    componentDidMount() {
        window.addEventListener("message", this._on_iframe_event);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this._on_iframe_event);
    }

    _on_iframe_message(event: MessageEvent) {
        if (event.data.from === "game") {
            const message_type = event.data.data[0];

            if (message_type === "game:init") {
                this._send_app_init();
            } else if (message_type === "player:state") {
                events.emit('player:state', event.data.data[1]);
            } else if (message_type === "session:results") {
                events.emit('session:results', event.data.data[1]);
            }
        }
    }

    _get_title() {
        if (this.props.tournament_id === 'fastfoodfrens' ||
            this.props.tournament_id === 'fastfoodfrens2')
            return 'FAST FOOD FRENS';
        return 'The DOGE NFT';
    }

    _send_app_init() {
        let data = {
            username: useSessionStore.getState().username,
            account_id: this.props.account_id,
            title: this._get_title(),
            avatars: useSessionStore.getState().avatars,
            small_avatar: useSessionStore.getState().small_avatar,
            room_id: this.props.room_id,
            invitation_key: this.props.invitation_key,
            subscription_id: this.props.subscription_id,
        };
        this.postMessage('app:init', data);
    }

    postMessage = (...args: any[]) => {
        const contentWindow = this.iframeRef.current?.contentWindow;
        if (contentWindow) {
            contentWindow.postMessage(
                {
                    sender: "front",
                    data: args,
                },
                "*"
            );
        }
    };

    render() {
        return (
            <div className="tic-root">
                <iframe
                    id="game"
                    ref={this.iframeRef}
                    src='/games/tournament/poker/index.html'
                    frameBorder="0"
                    allowFullScreen
                    title='poker'
                />
            </div>
        );
    }
}