import './wallets_test_page.css'
import { MetamaskBar } from './metamask_bar';
//import { WalletConnectBar } from './wallet_connect_bar';

interface Props {
}

export function WalletsTestPage(props: Props) {
    return (
        <div>
            <MetamaskBar/>
            {/*<WalletConnectBar/>*/}
        </div>
    );
}