import { useState } from "react";
import { post_json } from "../../core/fetch";
import { sha256 } from "../../core/hash";
import settings from "../../core/settings";
import storage from "../../core/storage";
import { validate_email } from "../../core/utils";
import { useSessionStore } from "../../stores/session_store";
import { doge_avatars } from "../avatars/doge_avatars";
import { AddPFPDialog } from "./add_pfp_dialog";
import './auth_signup_component.css'

export function AuthSignupComponent() {
    const [username, set_username] = useState('');
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');
    const [password2, set_password2] = useState('');
    const [avatars, set_avatars] = useState<any[]>(doge_avatars);
    const [avatar_index, set_avatar_index] = useState<number>(Math.floor(Math.random() * doge_avatars.length));
    const [error, set_error] = useState('');
    const [dialog_opened, set_dialog_opened] = useState(false);

    const on_signup = async () => {
        post_json(settings.accounts_server_url + '/api/signup', {
            address: email,
            name: username,
            password: await sha256(password),
            small_avatar: avatars[avatar_index].small,
        }).then(data => {
            //console.log('/api/signup', data);
            if (data.status === 200) {
                localStorage.setItem('tour-account-id', data.data);
                localStorage.setItem('tour-username', username);
                localStorage.setItem('tour-address', email);
                localStorage.setItem('tour-avatar', avatars[avatar_index].small);
                localStorage.setItem('tour-jwt', data.token);

                useSessionStore.setState({
                    account_id: data.data,
                    username: username,
                    small_avatar: avatars[avatar_index].small,
                });
                storage.set_jwt(data.token);
            } else {
                set_error('user exist')
            }
        });
    }

    const close_dialog = (data: any) => {
        if (data) {
            let new_avatars = [...avatars];
            new_avatars.push(data);
            set_avatars(new_avatars);
            set_avatar_index(new_avatars.length - 1);    
        }
        set_dialog_opened(false);
    }

    return (
        <div>
            <div>
                <div className='asc-text'>
                    ENTER YOUR EMAIL
                </div>
                <div>
                    <input className={validate_email(email) ? 'asc-input' : 'asc-input-error'} id="email" type="text" value={email} onChange={event => set_email(event.target.value.toLowerCase())} />
                </div>
                <div className='acs-error'>
                    {error}
                </div>
                <div className='asc-text'>
                    ENTER A PASSWORD
                </div>
                <div>
                    <input className='asc-input' id="password" type="password" value={password} onChange={event => set_password(event.target.value)} />
                </div>
                <div className='asc-text'>
                    CONFIRM A PASSWORD
                </div>
                <div>
                    <input className={password === password2 ? 'asc-input' : 'asc-input-error'} id="password2" type="password" value={password2} onChange={event => set_password2(event.target.value)} />
                </div>
                <div className='asc-text'>
                    ENTER YOUR NAME
                </div>
                <div>
                    <input className='asc-input' id="user_name" type="text" value={username} onChange={event => set_username(event.target.value)} />
                </div>
                <div className='asc-small_space' />
                <div>
                    <div className='asc-small-text'>
                        CHOOSE YOUR FIGHTER
                    </div>
                    <div className='asc-avatars-center'>
                        <div className='asc-avatars'>
                            {avatars.map((value, index) => {
                                return <div className={index === avatar_index ? 'asc-avatar-selected' : 'asc-avatar'} key={index}>
                                    <img className='asc-avatar-image' alt='avatar' src={value.small} onClick={() => set_avatar_index(index)} />
                                </div>
                            })}
                            <button className="asc-add-avatar" onClick={() => set_dialog_opened(true)}>
                                +
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <button className='asc-button' onClick={on_signup} disabled={username === '' || !validate_email(email) || password === '' || password !== password2}>
                        SIGNUP
                    </button>
                </div>
            </div>
            {
                dialog_opened && <AddPFPDialog on_close={close_dialog}/>
            }
        </div>
    );
}