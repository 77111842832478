import settings from '../../core/settings'
import './klink_page.css'

export function DemoPage() {
    return (
        <div className='kp-root' style={{ background: '#222' }}>
            <iframe id="claimr-widget-organizations" scrolling="no" frameBorder="0" style={{
                width: '100%',
                height: '100%',
            }}
                title="Test" src={`${settings.claimr_url}/widgets/v2/campaign/demo/test-campaign/`} />
        </div>
    )
}