import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { get_json } from "../../core/fetch";
import settings from "../../core/settings";
import { useAccountStore } from '../../stores/account_store';

export function OAuth2PromtComponent() {
    const account = useAccountStore(state => state.account);
    const access_token = useAccountStore(state => state.access_token);
    const fetch_account = useAccountStore(state => state.fetch_account);
    const reset = useAccountStore(state => state.reset);

    const [is_valid, set_valid] = useState(true);
    const [user_name, set_user_name] = useState('');
    const [app_name, set_app_name] = useState('');
    const [scopes, set_scopes] = useState([]);
    const [code_uri, set_code_uri] = useState('');
    const [code, set_code] = useState('');
    const [state, set_state] = useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!account)
            return;

        const client_id = searchParams.get('client_id');
        const redirect_uri = searchParams.get('redirect_uri');
        const response_type = searchParams.get('response_type');
        const scope = searchParams.get('scope');
        const state = searchParams.get('state');

        if (client_id && redirect_uri && response_type && scope && state) {
            const query = `${settings.accounts_server_url}/api/oauth2/code?account_id=${account.account_id}&client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}`;
            get_json(query, access_token).then(data => {
                //console.log('validate', data);
                if (data.status === 200 && data.data) {
                    set_user_name(account.name);
                    set_app_name(data.data.app_name);
                    set_scopes(data.data.scopes);
                    set_code(data.data.code);
                    set_code_uri(redirect_uri);
                    set_state(state);
                } else {
                    set_valid(false);
                }
            });
        } else {
            set_valid(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        if (access_token !== '') {
            fetch_account(access_token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access_token]);


    const on_accept = () => {
        window.location.assign(`${code_uri}?code=${code}&state=${state}`);
    }

    const on_back = () => {
        navigate(-1);
    }

    const on_logout = () => {
        reset();
    }

    return (
        <div>
            {
                is_valid ?
                    <div>
                        <div className='oap-text'>
                            {user_name}
                        </div>
                        <div className='oap-text'>
                            Application
                        </div>
                        <div className='oap-title-1'>
                            {app_name}
                        </div>
                        <div className='oap-big-text'>
                            asked for permissions:
                        </div>
                        <div>
                            {
                                scopes.map((scope, index) => {
                                    return (
                                        <div className='oap-text' key={index}>
                                            {scope}
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div>
                            <button className='oap-button' onClick={on_accept} disabled={!account}>
                                accept
                            </button>
                        </div>
                        <div>
                            <button className='oap-button' onClick={on_back}>
                                cancel
                            </button>
                        </div>
                        <div>
                            <button className='oap-button' onClick={on_logout}>
                                logout
                            </button>
                        </div>
                    </div>
                    :
                    <div>
                        Something went wrong...
                        <div>
                            <button className='oap-button' onClick={on_back}>
                                go back
                            </button>
                        </div>
                    </div>
            }
        </div>
    );
}