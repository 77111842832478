import { useEffect, useState } from "react";
import { post_json } from "../../core/fetch";
import settings from "../../core/settings";
import storage from "../../core/storage";
import { useSessionStore } from "../../stores/session_store";
import { AddPFPDialog } from "../auth/add_pfp_dialog";
import './tournament_profile_component.css'

export function TournamentProfileComponent() {
    const account_id = useSessionStore(state => state.account_id);
    const [account, set_account] = useState<any>(null);
    const [dialog_opened, set_dialog_opened] = useState(false);

    useEffect(() => {
        fetch_account();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetch_account = () => {
        post_json(settings.accounts_server_url + '/account', {
            account_id
        }).then(data => {
            //console.log('fetch_account', data);
            if (data.status === 200) {
                if (data.data) {
                    set_account(data.data);
                }
            }
        });
    }

    const close_dialog = (data: any) => {
        if (data) {
            post_json(settings.accounts_server_url + '/api/avatar', {
                account_id,
                small_avatar: data.small,
            }).then(data => {
                fetch_account();
            });

        }
        set_dialog_opened(false);
    }

    const on_logout = () => {
        localStorage.setItem('tour-account-id', '');
        localStorage.setItem('tour-username', '');
        localStorage.setItem('tour-address', '');
        localStorage.setItem('tour-avatar', '');
        localStorage.setItem('tour-jwt', '');

        useSessionStore.setState({
            account_id: '',
            username: '',
            small_avatar: '',
        });
        storage.set_jwt('');

    }

    return (
        <div>
            <div>
                {account &&
                    <>
                        <div className="tpc-account">
                            <img className='tpc-account-icon' alt='avatar' src={account.small_avatar.S} />
                            <span className="tpc-account-name">{account.name.S}</span>
                            <div>
                                <button className="tpc-button" onClick={() => set_dialog_opened(true)}>
                                    change picture
                                </button>
                            </div>
                            <div>
                                <button className="tpc-button" onClick={on_logout}>
                                    Logout
                                </button>
                            </div>
                        </div>
                        {
                            dialog_opened && <AddPFPDialog on_close={close_dialog} />
                        }
                    </>
                }
            </div>
        </div>
    )
}