export const fff_avatars = [
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/fff-0.jpg',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-0.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/fff-1.jpg',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-coldplunge.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/kumqwat.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-kumqwat.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/fff-3.jpg',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-0.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/adi.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-adi.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/max.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-max.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/kumqwat-o.png',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-kumqwat-o.png',
    },
    {
        small: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/fff-2.jpg',
        sunglasses: 'https://hmstrs-general.s3.eu-central-1.amazonaws.com/g-avatar-0.png',
    },
]