import { useEffect } from "react";
import { useWalletStore } from "../../../stores/wallet_store";
import { ethers } from "ethers";
import { useSessionStore } from "../../../stores/session_store";

import './fff_login_button.css';

let provider: any = null;

export function FFFLoginButton() {
    useEffect(() => {
        connect_provider();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const connect_provider = async () => {
        provider = new ethers.providers.Web3Provider(window.ethereum);
        //console.log('provider', provider);

        const address = provider.provider.selectedAddress;
        //console.log('address', address);

        const tour_address = localStorage.getItem('tour-address');

        if (address && tour_address !== address) {
            login(address);
        }
    }

    const login = async (address: string) => {
        try {
            const ens_name = await provider.lookupAddress(address);
            //console.log(ens_name);
            if (ens_name) {
                useSessionStore.setState({
                    username: ens_name,
                });
            }
        } catch(err) {
            console.log(`failed to get ens with error: ${err}`);
        }

        try {
            const signer = provider.getSigner();
            const signature = await signer.signMessage(address);
            useWalletStore.setState({
                address,
                signature,
            });
            /*post_json(settings.accounts_server_url + '/signup', {
                address,
                signature
            }).then(data => {
                if (data.data) {
                    console.log('login', data);
                    useWalletStore.setState({
                        address,
                    });
                    useSessionStore.setState({
                        account_id: data.data,
                    });
                    storage.set_jwt(data.token);    
                } else {
                    console.log(`failed to signup`);
                }
            }).catch(err => { console.log(`failed to post signup with error ${err}`); });*/
        } catch(err) {
            console.log(`failed to sign message`, err);
        }
    }

    const login_ethers = async () => {
        try {
            await provider.send("eth_requestAccounts", []);
            if (provider.provider.selectedAddress) {
                await login(provider.provider.selectedAddress);
            }    
        } catch(err) {
            console.log(`failed to login with error: ${err}`);
        }
    }

    return (
        <button className="fff-lb-button" onClick={login_ethers}>
            METAMASK
        </button>
    );
}