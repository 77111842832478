import { useState } from "react";
import { post_json } from "../../core/fetch";
import { sha256 } from "../../core/hash";
import settings from "../../core/settings";
import { validate_email } from "../../core/utils";

interface Props {
    on_close: any;
}

enum CurrentState {
    email,
    code,
    password
}

export function OAuth2RecoverDialog(props: Props) {
    const [current_state, set_current_state] = useState(CurrentState.email);
    const [email, set_email] = useState('');
    const [pin, set_pin] = useState('');
    const [password, set_password] = useState('');
    const [password2, set_password2] = useState('');

    const on_ok = () => {
        post_json(settings.accounts_server_url + '/api/auth/recover', {
            address: email,
        }).then(() => {
            set_current_state(CurrentState.code);
        });
    }

    const on_check = () => {
        post_json(settings.accounts_server_url + '/api/auth/pin', {
            address: email,
            pin,
        }).then((data) => {
            if (data.status === 200) {
                set_current_state(CurrentState.password);
            } else {
                // wrong code
            }
        });
    }

    const on_reset = async () => {
        post_json(settings.accounts_server_url + '/api/auth/reset', {
            address: email,
            password: await sha256(password),
            pin,
        }).then(() => {
            props.on_close(true);
        });

        props.on_close();
    }

    const on_cancel = () => {
        props.on_close();
    }

    return (
        <div className='oap-overlay'>
            <div className='oap-dialog'>
                <div className='oap-big-text'>
                    Password Recover
                </div>
                <div className='oap-big-space' />
                {
                    current_state === CurrentState.email &&
                    <div>
                        <div className='oap-text'>
                            Enter Your E-mail
                        </div>
                        <div>
                            <input className={validate_email(email) ? 'oap-input' : 'oap-input-error'} id="email" type="text" value={email} onChange={event => set_email(event.target.value.toLowerCase())} />
                        </div>
                        <div className='oap-big-space' />
                        <div>
                            <button className='oap-button' onClick={on_ok} disabled={!validate_email(email)}>
                                Ok
                            </button>
                        </div>
                    </div>
                }
                {
                    current_state === CurrentState.code &&
                    <div>
                        <div className='oap-text'>
                            Enter Code From E-mail
                        </div>
                        <div>
                            <input className='oap-input' id="recover-code" type="text" value={pin} onChange={event => set_pin(event.target.value.toLowerCase())} />
                        </div>
                        <div className='oap-big-space' />
                        <div>
                            <button className='oap-button' onClick={on_check} disabled={pin === ''}>
                                Check
                            </button>
                        </div>
                    </div>
                }
                {
                    current_state === CurrentState.password &&
                    <div>
                        <div className='oap-text'>
                            Enter New Password
                        </div>
                        <div>
                            <input className='oap-input' id="password" type="password" value={password} onChange={event => set_password(event.target.value)} />
                        </div>
                        <div className='oap-text'>
                            Confirm a Password
                        </div>
                        <div>
                            <input className={password === password2 ? 'oap-input' : 'oap-input-error'} id="password2" type="password" value={password2} onChange={event => set_password2(event.target.value)} />
                        </div>
                        <div className='oap-big-space' />
                        <div>
                            <button className='oap-button' onClick={on_reset} disabled={password === '' || password !== password2}>
                                Reset Password
                            </button>
                        </div>
                    </div>
                }
                <div>
                    <button className='oap-button' onClick={on_cancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}