import { useState } from "react";
import { post_json } from "../../core/fetch";
import { sha256 } from "../../core/hash";
import settings from "../../core/settings";
import storage from "../../core/storage";
import { validate_email } from "../../core/utils";
import { useSessionStore } from "../../stores/session_store";
import './auth_login_component.css'
import { AuthRecoverDialog } from "./auth_recover_dialog";

export function AuthLoginComponent() {
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');
    const [error, set_error] = useState('');
    const [dialog_opened, set_dialog_opened] = useState(false);

    const on_login = async () => {
        post_json(settings.accounts_server_url + '/api/login', {
            address: email,
            password: await sha256(password),
        }).then(data => {
            //console.log('/api/login', data);
            if (data.status === 200) {
                localStorage.setItem('tour-account-id', data.data.account_id.S);
                localStorage.setItem('tour-username', data.data.name.S);
                localStorage.setItem('tour-address', email);
                localStorage.setItem('tour-avatar', data.data.small_avatar.S);
                localStorage.setItem('tour-jwt', data.token);

                useSessionStore.setState({
                    account_id: data.data.account_id.S,
                    username: data.data.name.S,
                    small_avatar: data.data.small_avatar.S,
                });
                storage.set_jwt(data.token);
            } else {
                set_error('INVALID PASSWORD OR ACCOUNT NOT FOUND');
            }
        });
    }

    const on_recover = () => {
        set_dialog_opened(true);
    }

    const close_dialog = (value: boolean) => {
        set_dialog_opened(false);
        if (value) {
            set_error('PLEASE CHECK YOUR EMAIL TO RECOVER PASSWORD')
        }
    }

    return (
        <div>
            <div className='alc-big_space' />
            <div>
                <div className='alc-text'>
                    EMAIL
                </div>
                <div>
                    <input className={validate_email(email) ? 'alc-input' : 'alc-input-error'} id="email" type="text" value={email} onChange={event => set_email(event.target.value.toLowerCase())} />
                </div>
                <div className='alc-small_space' />
                <div className='alc-text'>
                    PASSWORD
                </div>
                <div>
                    <input className='alc-input' id="password" type="password" value={password} onChange={event => set_password(event.target.value)} />
                </div>
                <div className='alc-error'>
                    {error}
                </div>
                <div>
                    <button className='alc-button' onClick={on_login} disabled={!validate_email(email) || password === ''}>
                        LOGIN
                    </button>
                    <button className='alc-button' onClick={on_recover}>
                        FORGOT PASSWORD
                    </button>
                </div>
            </div>
            {dialog_opened && <AuthRecoverDialog on_close={close_dialog}/>}
        </div>
    );
}